import Base from "../../../base";
import DeviceTypeListLayout from "../layout";

export default class DeviceTypeList extends Base
{
    render() {
        return (
            <DeviceTypeListLayout
				search={{status:1}}
				canCheck={true}
				btnShow={false}
				oprationShow={false}
				searchFields={['keywords']}
            />
        );
    }
}
