import Table from "../../../layout/table";
import OrderInstallService from "../../../../services/order/install";
import View from "../../../../../../vendor/framework/view";
import OrderRepealService from "../../../../services/order/repeal";
import $ from 'jquery';
import Excel from "../../../../components/excel";
import File from "../../../../../../vendor/framework/file";

export default class OrderInstallListLayout extends View {
    service() {
        return OrderInstallService;
    }

    render() {
        return (
            <Table
                service={OrderInstallService}
                props={this.props}
                canCheck={true}
                checkMany={true}
                stateInit={{
                    orderBy: ['id', 'desc']
                }}
                searchInit={{
					keywords: {name: '关键字', placeholder:'商户编号/工单编号/商户名称/终端号/安装人', enter: true},
                    status: {name: '状态', type: 'select', data: this.server.status, default: ''},
                    dateMin: {name: '添加时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间'},
                    dateMax: {type: 'date', position: 1,format:'datetime', placeholder: '结束时间'},
					dateMin1:{name: '完成时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间',field:'served_at',key:"dateMin"},
					dateMax1:{type: 'date', position: 1, format:'datetime',placeholder: '结束时间',field:'served_at',key:"dateMax"},
                }}
                fieldsInit={{
                    id: {name: 'ID', sort: {asc: 'asc', desc: 'desc'}, checkbox: true, style:{whiteSpace:'nowrap'}},
                    orderSn: '工单编号',
                    typeDup: '工单类型',
                    company: {
                        name: '所属公司', value(model) {
                            return model.client && model.client.company ? model.client.company.name : ''
                        },
                        style:{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                    },
                    clientName: {
                        name: '商户名称', value(model) {
                            return model.client ? model.client.name : '';
                        }
                    },
                    clientCode:{
                        name:'商户编号', value: model => model.client.code
                    },
                    terminalCode:'终端号',
                    principalUser: {
                        name: '安装人', value(model) {
                            return model.installUser ? model.installUser.realname : '';
                        }
                    },
                    visitedAt: {
                        name: '上门时间',
                        sort: {asc: 'asc', desc: 'desc', key: 'visited_at'},
                        style:{width:'140px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                        },
                    servedAt: {
                        name: '完成时间',
                        sort: {asc: 'asc', desc: 'desc', key: 'served_at'},
                        style:{width:'140px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                    },
                    statusDup: '状态',
                    createdAt: {
                        name:'添加时间',
                        style:{width:'140px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                    }
                }}
                btnInit={{
                    add: {name: '添加', click: this.add.bind(this)},
                    install: {name: '指派', click: this.install.bind(this)},
                    manyInstallLayout:{name:'批量指派模板下载', click: this.manyInstallLayout.bind(this)},
                    manyInstallImport:{name:'批量指派', click: this.manyInstallImport.bind(this)},
                    excelLayout: {name: 'EXCEL模板下载', className: 'layui-btn-warm', click: this.excelLayout.bind(this)},
                    import:{name:'导入', className:'layui-btn-normal', click: this.import.bind(this)},
                    export:{name:'导出', click: this.export.bind(this)},
                    download:{name:'下载', click: this.download.bind(this)},
                }}
                oprationInit={{
                    audit:{name:'审核', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => model.status !== 1 , click: this.audit.bind(this)},
                    detail: {name: '详情', icon: ['list', 'normal'], click: this.detail.bind(this)},
                    edit: {name: '修改', icon: ['edit', 'normal'], click: this.edit.bind(this)},
                   // patrol: {name: '巡检列表', icon: ['list', 'normal'], disabled: model => !model.hasPatrol, click: this.patrol.bind(this)},
                    //repeal: {name: '撤机', icon: ['close', 'warm'], disabled: model => model.isRepeal, click: this.repeal.bind(this)},
                    delete: {name: '删除', icon: ['delete', 'danger'], click: this.delete.bind(this)}
                }}
                headerStyle={{
                    whiteSpace:'nowrap'
                }}
                bodyStyle={{
                    whiteSpace:'nowrap',
                }}
                oprationStyle={{
                    whiteSpace:'nowrap',
                }}
            />
        );
    }

    buttonComponent(table) {
        return (
            <button className={"layui-btn"}
                    onClick={e => this.chooseCompany(table)}>{table.state.companyName || '所有公司'}</button>
        )
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add(table) {
        this.layOpen({
            title: '添加工单',
            content: '/admin/order/install/add',
            form: true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title: '修改工单 ' + val.orderSn,
            content: '/admin/order/install/edit/' + val.id,
            form: true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                field.id = val.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    repeal(model, table) {
        this.layOpen({
            title: '选择撤机人',
            content: '/admin/company/user/choose/list',
            form: true,
            area: ['900px', '90%'],
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                form.ids = [model.id];
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择撤机人!');
                }
                form.repealUid = JSON.parse(data[0]).id;
                table.server.getService(OrderRepealService).repeal(form, () => {
                    layer.msg('生成撤机工单!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除工单号为 ' + model.orderSn + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    patrol(model) {
        this.layOpen({
            title: '巡检列表',
            content: '/admin/order/patrol/list?installId=' + model.id,
            area: ['95%', '98%'],
            btn: ['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    detail(model) {
        this.layOpen({
            title: '工单详情 ' + model.orderSn,
            content: '/admin/order/install/detail/' + model.id,
            area: ['90%', '98%'],
            btn: ['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    install(table) {
        let ids = [];
        $('input[type=checkbox]').each(function () {
            if ($(this).prop('checked')) {
                let value = $(this).val();
                if (value.indexOf('{') > -1 && value.indexOf('}') > -1) {
                    let val = JSON.parse(value);
                    ids.push(val.id);
                }
            }
        })
        if (!ids.length) {
            return layer.msg('请选择要指派的工单!');
        }

        this.layOpen({
            title: '选择安装员工',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择员工!');
                }
                let user = JSON.parse(data[0]);
                let model = {
                    ids: ids,
                    uid: user.id
                }
                table.server.setInstallUser(model, () => {
                    layer.msg('已指派!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    excelLayout() {
        let keys = Object.keys(this.server.excelFields),
            arr = [],
            data = [
                {
                    'id':'1',
                    'orderSn':'I2022010211582210',
                    'type':'间联单',
                    'company':'广州裕富联分公司',
                    'clientCode':'Y2021111707591110',
                    'clientName':'深圳宝安商户',
                    'corporate':'陈某',
                    'corporatePhone':'13022222222',
                    'corporateTel':'0757-123456',
                    'corporateFax':'0757-123456',
                    'corporateIdcode':'123456789',
                    'corporateValidityStartDate':'',
                    'corporateValidityEndDate':'',
                    'username':'张小明',
                    'phone':'13022222222',
                    'address':'广州天河',
                    'district':'',
                    'contractor':'',
                    'terminalCode':'20000001',
                    'receiveBank':'中国工商银行',
                    'bank':'中国工商银行',
                    'lendRate':'1%',
                    'loanRate':'1%',
                    'outRate':'1%',
                    'originRate':'1%',
                    'specialCoast':'50',
                    'level':'2',
                    'expansion':'',
                    'expansionCoast':'10',
                    'platform':'',
                    'bankAgent':'',
                    'expansionType':'',
                    'channel':'',
                    'transactionControl':'',
                    'receiveDate':'',
                    'postcode':'5100000',
                    'serialNumber':'',
                    'risk':'1',
                    'authorized':'',
                    'authorizedIdcode':'',
                    'authorizedValidityStartDate':'',
                    'authorizedValidityEndDate':'',
                    'registerDate':'',
                    'startDate':'',
                    'aliCoastRate':'',
                    'wechatCoastRate':'',
                    'posModel':'',
                    'posDeviceNum':'',
                    'posMade':'',
                    'keyboardModel':'',
                    'keyboardDeviceNum':'',
                    'keyboardMade':'',
                    'simModel':'',
                    'simDeviceNum':'',
                    'simMade':'',
                    'result':'',
                    'installUser':'',
                    'visitedAt':'',
                    'servedAt':'',
                    'salesman':'',
                    'salesmanNum':'',
                    'externalChannels':'小明',
                    'clientManagerPhone':'13000000000',
                }
            ];
        arr[0] = this.server.getExcelTitles();
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('工单导入模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:BK1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    import(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/install/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.import(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    export(table, type = 0) {
        let ids = this.getCheckIds();
        if (! ids.length) {
            return layer.msg('请选择数据!');
        }
        this.server.export({ids:ids, type: type}, res => {
            if (type) {
                let url = (new File()).make(res.path);
                 window.open(url);
                return;
            }
            let list = [];
            res.forEach(item => {
                if (ids.indexOf(item.id) > -1) {
                    list.push(item);
                }
            })
            let titles = this.server.getExcelTitles();
            titles.push("结果");
            titles.push("理由");
            let keys = Object.keys(this.server.excelFields),
                data = [titles];
            keys.push("result1");
            keys.push("reason");

            list.forEach(item => {
                let arr = [],
                    device = item.device || {},
                    company = item.client.company  || {},
                    installUser = item.installUser || {},
                    receiveBank = device.receiveBankModel || {},
                    bank = device.bankModel || {},
                    row = {
                        id:item.id,
                        type:item.typeDup,
                        company:company.name,
                        clientCode:item.client.code,
                        clientName:item.client.name,
                        clientCorporate:item.corporate,
                        result:item.statusDup,
                        installUser:installUser.realname,
                        receiveBank:receiveBank.name,
                        bank:bank.name,
                        result1:item.resultDup,
                        reason:item.reason
                    }
                keys.forEach(field => {
                    if (item.hasOwnProperty(field) && !row.hasOwnProperty(field) && !device.hasOwnProperty(field)) {
                        row[field] = item[field];
                    }
                })
                Object.keys(device).forEach(field => {
                    if (! row.hasOwnProperty(field) && keys.indexOf(field) > -1) {
                        row[field] = device[field];
                    }
                })
                keys.forEach(field => {
                    arr.push(row[field] || '');
                })
                data.push(arr);
            })

            let excel = new Excel();
            excel.export('工单列表', data, (workbook, table) => {
                workbook.sheet(0).row(1).height(50);
                workbook.sheets().forEach((sheet) => {
                    // 表头加粗及背景色
                    sheet.range('A1:BM1').style({
                        wrapText: true,
                        fill: "00bdf0",
                        bold: true,
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                        fontSize: 12,
                        border: {
                            style: 'thin',
                            color: "000000",
                            direction: "both"
                        }
                    });

                    for (let k in table[0]) {
                        sheet.column(k).width(25);
                    }
                })
            })
        })
    }

    manyInstallLayout() {
        let keys = ['id', 'orderSn', 'installUser'],
            arr = [],
            data = [
                {
                    'id':'1',
                    'orderSn':'I2022010211582210',
                    'installUser':'志星',
                }
            ];
        arr[0] = ['序号', '工单编号', '安装员工'];
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('批量指派模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:C1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    manyInstallImport(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/install/install/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.manyInstall(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    audit(model, table) {
        layer.confirm('审核结果', {icon:3, title:'审核', btn:['通过', '拒绝', '取消']}, () => {
            audit(3);
        }, () => {
            layer.prompt({
                formType:2,
                title:'原因',
            }, (val, index) => {
                audit(2, val);
                layer.close(index)
            })

        })

        function audit(status, reason) {
            table.server.audit({id:model.id, status:status, reason:reason}, () => {
                layer.msg('保存成功!');
                table.pageReload();
            }, message => {
                layer.msg(message);
            })
        }
    }

    download() {
        this.export(null, 1);
    }
}
