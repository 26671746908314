import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class DeviceService extends BaseService
{
    requestUrls = {
        url:'/device/list',
        add:'/device/addAll',
        detail:'/device/detail',
		export:'/device/export',
		bindUserUrl:'/device/bind',
		
    }
	
	
	status = ['未出库', '已出库'];
	deviceStatusName = ['未安装','已安装','已撤机','已更换'];
	
	//序号id,产权property，所属公司=>'company'， 设备类型=>type，生产厂商=>made，设备型号=>model  ，设备号 (机身号)=》deviceNum，库存状态deviceStatus，
	 //设备状态， 商户号=》comno， 终端号 terminal ， 商户名称， 领取人recipient，

	excelFields = ['id', 'property','company', 'type','made','model', 'deviceNum', 'status','deviceStatus','comno','terminal','merchant','recipient'];
	exportTitle= {
	'id':'序号', 
	'property':'产权', 
	'company':'所属公司', 
	'typeDup':'设备类型', 
	'made':'生产厂商', 
	'model':'设备型号', 	
	'deviceNum':'设备号', 
	'statusDup':'库存状态', 
	'deviceStatusDup':'设备状态', 
	'comno':'商户号', 
	'terminal':'终端号', 
	'merchant':'商户名称', 
	'recipient':'领取人', 
 	'createdAt':'入库时间',
	'proddedAt':'出库时间',
	'logDup':'日志记录',
	};
	
	 
	getExcelTitles() {
	    let titles = [];
	    Object.keys(this.exportTitle).forEach(item => {
	        titles.push(this.exportTitle[item]);
	    })
	    return titles;
	}

    
	deviceType=[];

    add(data, suc, err) {
        let rules = [
                ['data', 'required|array', '没有数据上传|没有数据上传'],
                ['data.*.model', 'required', '设备型号不能为空'],
                ['data.*.deviceNum', 'required', '设备号 (机身号)不能为空'],
                ['data.*.made', 'required', '生产厂商不能为空'],
				['data.*.type', 'required', '设备类型不能为空'],
				['data.*.company', 'required', '所属公司不能为空'],
				['data.*.property', 'required', '产权不能为空'],
				['data.*.deviceStatus', 'required', '设备状态111不能为空'],
				['data.*.status', 'required', '库存状态不能为空'],
				['data.*.comno', 'required', '商户号不能为空'],
				//['data.*.terminal', 'required', '终端号不能为空'],
				['data.*.merchant', 'required', '商户名称不能为空'],
				//['data.*.recipient', 'required', '领取人不能为空'],
            ],
            validator = new Validator();
        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
         
        this.post(
            this.requestUrls.add,
            data,
            suc,
            err
        )
    }


    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }
 
	

	export(ids, suc) {
	    this.post(
	        this.requestUrls.export,
	        {ids:ids},
	        suc
	    )
	}
	
	
	bindUser(data, suc, err) {
	    this.post(
	        this.requestUrls.bindUserUrl,
	        data,
	        suc,
	        err
	    )
	}
	
	
	
}
