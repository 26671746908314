import Base from "../../../base";
import Form from "../../../layout/form";
import CompanyUserService from "../../../../services/company/user";
import BankListLayout from "../../../running/bank/layout";

export default class CompanyUserAdd extends Base
{
    boot() {
        this.state = {
            model:{
                username:'',
                portrait:'',
                realname:'',
                phone:'',
                password:'',
                status:1,
                locateMode:1,
                bankAccount:'',
                bankName:'',
                bankId:0
            },
            bank:[]
        }
        /* global layer */
    }

    service() {
        return CompanyUserService;
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    portrait: {name:'头像', type:'image'},
                    username:'员工账号',
                    password:{name:'密码', type: 'password'},
                    realname:'姓名',
                    phone:'手机号码',
                    locateMode:{name:'定位方式', type:'select', data: this.server.locateMode},
                    bank:{name:'银行', type:'choose', component: this.bank.bind(this), click: this.chooseBank.bind(this)},
                    backAccount:'银行账号',
                    status:{name:'状态', type:'select', data: this.server.status}
                }}
            />
        );
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }
}

