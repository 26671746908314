import Base from "../../../base";
import OrderInstallService from "../../../../services/order/install";
import Form from "../../../layout/form";
import UserListLayout from "../../../user/layout/list";
import Table from "../../../layout/table";
import CompanyUserListLayout from "../../../company/user/layout";
import CompanyUserService from "../../../../services/company/user";
import UserService from "../../../../services/user";
import $ from 'jquery';
import PosListLayout from "../../../device/pos/layout";
import KeybordListLayout from "../../../device/keybord/layout";
import SimListLayout from "../../../device/sim/layout";
import BankService from "../../../../services/running/bank";
import BankListLayout from "../../../running/bank/layout";
import PosService from "../../../../services/device/pos";
import { Fragment } from 'react';
import UserDeviceListLayout from "../../../user/device/layout";

export default class OrderInstallEdit extends Base {
	 
    boot() {
        this.state = {
            model: {
                uid: 0,
                username:'',
                phone:'',
                address:'',
                remark:'',
                installUid:0,
                type:1,
                status:0,
				facadeImg:[],
				innerImg:[],
				deskImg:[],
				physicalImg:[],
				salesImg:[],
				signImg:[],
  				
            },
            client: [],
            installUser:[],
            checkUser:[],
            receiveBank:[],
            bank:[],
            pos:[],
            keyboard:[],
            sim:[],
            terminal:[]
        }
    }

    async getDetail() {
        await this.server.getDetail(this.getPropsParams('id'), data => {
            // if (data.status) {
            //     $('body', window.parent.document).find('.layui-layer-btn0').hide();
            //     $('body', window.parent.document).find('.layui-layer-btn1').before('<a class="layui-layer-btn1">不可修改</a>');
            // }

            if (! data.device) {
                data.device = {};
            }

            data.terminalCode =  data.device.terminalCode;
            data.deviceId =  data.device.id;
			data.salesImg=data.salesImg? data.salesImg.split(','):[];
			data.facadeImg=data.facadeImg? data.facadeImg.split(','):[];
			data.innerImg=data.innerImg? data.innerImg.split(','):[];
			data.deskImg=data.deskImg? data.deskImg.split(','):[];
			data.physicalImg=data.physicalImg? data.physicalImg.split(','):[];
			data.signImg=data.signImg? data.signImg.split(','):[];
		 
			
			  
            // if (data.device.posId) {
            //     this.server.getService(PosService).getDetail(data.device.posId, pos => {
            //         this.setState({
            //             pos:[pos]
            //         })
            //     })
            // }
            // if (data.device.keyboardId) {
            //     this.server.getService(PosService).getDetail(data.device.keyboardId, keyboard => {
            //         this.setState({
            //             keyboard:[keyboard]
            //         })
            //     })
            // }
            //  if (data.device.simId) {
            //      this.server.getService(PosService).getDetail(data.device.simId, sim => {
            //          this.setState({
            //              sim:[sim]
            //          })
            //      })
            //  }


            this.setModel(data);
            this.server.getService(UserService).getDetail(data.uid, user => {
                this.setState({
                    client: [user]
                })
            })
            if (data.installUid) {
                this.server.getService(CompanyUserService).getDetail(data.installUid, companyUser => {
                    this.setState({
                        installUser:[companyUser]
                    })
                })
            }
            if (data.device.id) {
                this.setState({
                    terminal:[data.device]
                })
            }
            if (data.device.receiveBank) {
                this.server.getService(BankService).getDetail(data.device.receiveBank, bank => {
                    this.setState({
                        receiveBank:[bank]
                    })
                })
            }
            if (data.device.bank) {
                this.server.getService(BankService).getDetail(data.device.bank, bank => {
                    this.setState({
                        bank:[bank]
                    })
                })
            }
        })
    }

    componentDidMount() {
        /* global layer */
        this.getDetail();
    }

    service() {
        return OrderInstallService;
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    base: {
                        type: 'group',
                        name: '基础信息',
                        fields: {
                            client: {
                                name: '商户',
                                type: 'choose',
                                component: this.client.bind(this),
                                click: this.chooseClient.bind(this)
                            },
                            type: {name: '单据类型', type: 'select', data: this.server.type},
                            username:'联系人',
                            phone:'联系电话',
                            address:{name:'安装地址', type:'textarea'},
                            remark:{name:'备注(问题描述)', type:'textarea'},
                            visitedAt:{name:'上门时间', type:'date', format:'datetime'},
                            installUser:{
                                name:'安装人',
                                type:'choose',
                                component: this.installUser.bind(this),
                                click: this.chooseInstallUser.bind(this)
                            },
                            status:{
                                name:'状态',
                                type:'select',
                                data:this.server.status
                            }
                        }
                    },
                    pos: {
                        type: 'group',
                        name: '终端信息',
                        fields: {
                            terminal: {
                                name: '终端',
                                type: 'choose',
                                component: this.terminal.bind(this),
                                click: this.chooseTerminal.bind(this),
								facadeImg:{name:'门面照', type:'slide'},
                            },
                            // pos:{
                            //     name:'POS机',
                            //     type:'choose',
                            //     click: this.choosePos.bind(this),
                            //     component:(
                            //         <Fragment>
                            //             <PosListLayout
                            //                 autoload={false}
                            //                 list={this.state.pos}
                            //                 show={this.state.pos.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"posId"}
                            //             />
                            //             <div style={{display:'none'}}>
                            //                 {
                            //                     this.state.pos.map((val, index) => {
                            //                         return (
                            //                             <Fragment key={index}>
                            //                                 <input type="text" name={"posId"} value={val.id}/>
                            //                                 <input type="text" name={"posModel"} value={val.model}/>
                            //                                 <input type="text" name={"posDeviceNum"} value={val.deviceNum}/>
                            //                                 <input type="text" name={"posMade"} value={val.made}/>
                            //                             </Fragment>
                            //                         )
                            //                     })
                            //                 }
                            //             </div>
                            //         </Fragment>
                            //     )
                            // },
                            // keyboard:{
                            //     type:'choose',
                            //     name:'键盘',
                            //     click: this.chooseKeyboard.bind(this),
                            //     component:(
                            //         <Fragment>
                            //             <KeybordListLayout
                            //                 autoload={false}
                            //                 list={this.state.keyboard}
                            //                 show={this.state.keyboard.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"keyboardId"}
                            //             />
                            //             <div style={{display:'none'}}>
                            //                 {
                            //                     this.state.keyboard.map((val, index) => {
                            //                         return (
                            //                             <Fragment key={index}>
                            //                                 <input type="text" name={"keyboardId"} value={val.id}/>
                            //                                 <input type="text" name={"keyboardModel"} value={val.model}/>
                            //                                 <input type="text" name={"keyboardDeviceNum"} value={val.deviceNum}/>
                            //                                 <input type="text" name={"keyboardMade"} value={val.made}/>
                            //                             </Fragment>
                            //                         )
                            //                     })
                            //                 }
                            //             </div>
                            //         </Fragment>
                            //     )
                            // },
                            // sim:{
                            //     type:'choose',
                            //     name:'SIM卡',
                            //     click: this.chooseSim.bind(this),
                            //     component:(
                            //         <Fragment>
                            //             <SimListLayout
                            //                 autoload={false}
                            //                 list={this.state.sim}
                            //                 show={this.state.sim.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"simId"}
                            //             />
                            //             <div style={{display:'none'}}>
                            //                 {
                            //                     this.state.sim.map((val, index) => {
                            //                         return (
                            //                             <Fragment key={index}>
                            //                                 <input type="text" name={"simId"} value={val.id}/>
                            //                                 <input type="text" name={"simModel"} value={val.model}/>
                            //                                 <input type="text" name={"simDeviceNum"} value={val.deviceNum}/>
                            //                                 <input type="text" name={"simMade"} value={val.made}/>
                            //                             </Fragment>
                            //                         )
                            //                     })
                            //                 }
                            //             </div>
                            //         </Fragment>
                            //     )
                            // },
                            // terminalCode:'终端号',
                            // username:'联系人',
                            // phone:'联系电话',
                            // address:{name:'安装地址', type:'textarea'},
                            // receiveBank:{name:'收单银行', type:'choose', component: this.receiveBank.bind(this), click: this.chooseReceiveBank.bind(this)},
                            // bank:{name:'开户银行', type:'choose', component: this.bank.bind(this), click: this.chooseBank.bind(this)},
                            // box:{
                            //     type:'box',
                            //     key:'device',
                            //     fields:{
                            //         deviceId:{type:'hidden', key:'id', inputName:'deviceId'},
                            //         lendRate:{name:'费率(借)'},
                            //         loanRate:{name:'费率(贷)'},
                            //         outRate:{name:'费率(外)'},
                            //         originRate:{name:'费率(本)'},
                            //         specialCoast:{name:'特殊计费'},
                            //         level:{name:'商户等级'},
                            //         expansion:{name:'拓展人'},
                            //         expansionCoast:{name:'拓展费用'},
                            //         platform:{name:'所属平台'},
                            //         bankAgent:{name:'银行经办人'},
                            //         expansionType:{name:'拓展方式'},
                            //         channel:{name:'渠道'},
                            //         transactionControl:{name:'交易控制'},
                            //         receiveDate:{name:'收单日期', type:'date'},
                            //         postcode:{name:'邮政编码'},
                            //         serialNumber:{name:'申请序号'},
                            //         risk:{name:'风险等级'},
                            //         corporate:'法人代表',
                            //         corporateIdcode:{name:'法人证件号', title:'法人代表证件号', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}},
                            //         corporateValidityStartDate:{name:'有效期开始', type:'date', title:'法人代表证件号有效期开始'},
                            //         corporateValidityEndDate:{name:'有效期结束', type:'date', title:'法人代表证件号有效期结束'},
                            //         corporatePhone:'法人手机',
                            //         corporateTel:'法人固话',
                            //         corporateFax:'法人传真',
                            //         authorized:{name:'被授权人'},
                            //         authorizedIdcode:{name:'身份证', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'被授权人身份证'},
                            //         authorizedValidityStartDate:{name:'有效期开始', type:'date', title:'被授权人身份证有效期开始'},
                            //         authorizedValidityEndDate:{name:'有效期结束', type:'date', title:'被授权人身份证有效期结束'},
                            //         registerDate:{name:'登记日期', type:'date'},
                            //         startDate:{name:'启用日期', type:'date'},
                            //         aliCoastRate:{name:'支付宝费率'},
                            //         wechatCoastRate:{name:'微信费率'},
                            //         salesman:'业务员',
                            //         salesmanNum:'业务员工号',
                            //         externalChannels:'外部渠道',
                            //         clientManagerPhone:{name:'客户经理电话', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'客户经理电话'},
                            //         district:'区域',
                            //         contractor:'承包人',
                            //     }
                            // }
                        }
                    },orders:{
						 type: 'group',
						 name:'安装信息',
						 fields:{
						     innerImg:{name:'室内照', type:'slide'},
						     deskImg:{name:'前台照', type:'slide'},
						     physicalImg:{name:'机身照', type:'slide'},
						     salesImg:{name:'签购单', type:'slide'},
						 	 signImg:{name:'签名照', type:'slide'},
							 result:{name:'结果', type: 'radio', data: this.server.result,labelStyle:{whiteSpace: 'nowrap'}},
						     reason:{name:'备注原因'},
						 	 posDeviceNum:{name:'机身号'}
						 } 
						}
                }}
            />
        );
    }

    terminal() {
        return (
            <UserDeviceListLayout
                autoload={false}
                show={this.state.terminal.length}
                list={this.state.terminal}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'terminalId'}
                visibles={['id', 'clientImage', 'client.code', 'client.name', 'terminalCode', 'client.bankAddress','deviceRemark']}
            />
        )
    }

    chooseTerminal() {
        let client = this.state.client[0];
        if (!client) {
            return layer.msg('请先选择商户!');
        }
        this.layOpen({
            title: '选择终端',
            content: '/admin/user/device/choose/list?uid='+client.id+'&status=0',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择终端');
                }
                let terminal = JSON.parse(data[0]);
                if (terminal.contractor) {
                    this.server.getService(CompanyUserService).searchList(terminal.contractor, list => {
                        if (list.data.length) {
                            this.setState({
                                installUser:[list.list[0]]
                            })
                        }
                    })
                }
                this.setState({
                    terminal: [terminal]
                })
                layer.close(index);
            }
        })
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        let company = this.state.company[0];
        if (!company) {
            return layer.msg('请先选择所属公司!');
        }

        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }

                let client = JSON.parse(data[0]);
                this.setState( {
                    client: [client]
                })
                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    let pei = {
                        username:'contact',
                        phone:'tel'
                    };
                    let fields = ['bankAddress', 'address', 'corporate', 'corporateIdcode', 'corporateValidityStartDate', 'corporateValidityEndDate', 'corporatePhone', 'corporateTel', 'corporateFax',
                    ];
                    let deviceFields = [
                        'lendRate', 'loanRate','outRate','originRate','specialCoast', 'level','expansion','expansionCoast','platform','bankAgent','expansionType','channel',
                        'transactionControl', 'receiveDate','postcode','serialNumber','risk','registerDate','startDate','aliCoastRate','wechatCoastRate','authorized', 'authorizedIdcode', 'authorizedValidityStartDate', 'authorizedValidityEndDate'
                    ];
                    for(let k in fields) {
                        let field = fields[k];
                        // if (data.information.hasOwnProperty(field) && !model[field]) {
                        model[field] = data.information[field];
                        // }
                    }
                    for(let k in deviceFields) {
                        let field = deviceFields[k];
                        // if (data.information.hasOwnProperty(field) && !model[field]) {
                        model.device[field] = data.information[field];
                        // }
                    }
                    for(let k in pei) {
                        let field = pei[k];
                        // if (! model[k] && data.information[field]) {
                        model[k] = data.information[field];
                        // }
                    }
                    if (data.information.receiveBank) {
                        this.server.getService(BankService).getDetail(data.information.receiveBank, bank => {
                            this.setState({
                                receiveBank:[bank]
                            })
                        })
                    }
                    if (data.information.bank) {
                        this.server.getService(BankService).getDetail(data.information.bank, bank => {
                            this.setState({
                                bank:[bank]
                            })
                        })
                    }
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }

    installUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.installUser}
                show={this.state.installUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'installUid'}
            />
        )
    }

    chooseInstallUser() {
        this.layOpen({
            title: '选择安装人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.installUid = user.id;
                    return {
                        installUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }


    checkUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.checkUser}
                show={this.state.checkUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'checkUid'}
            />
        )
    }

    chooseCheckUser() {
        this.layOpen({
            title: '选择巡检人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.checkUid = user.id;
                    return {
                        checkUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    choosePos() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'选择POS机',
            content:'/admin/device/pos/choose/list?companyId=' + company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    pos:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseKeyboard() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'选择键盘',
            content:'/admin/device/keyboard/choose/list?companyId='+ company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    keyboard: [JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseSim() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'SIM卡',
            content:'/admin/device/sim/choose/list?companyId='+ company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    sim:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    receiveBank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.receiveBank.length}
                list={state.receiveBank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'receiveBankId'}
            />
        )
    }

    chooseReceiveBank(form) {
        this.layOpen({
            title:'选择收单银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    receiveBank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择开户银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    // deviceList() {
    //     return (
    //         <Table
    //             autoload={false}
    //             list={this.state.model.device}
    //             listTitleHeaderComponent={
    //                 <tr>
    //                     <th colSpan={4} style={{textAlign: 'center'}}>POS机</th>
    //                     <th colSpan={3} style={{textAlign: 'center'}}>键盘</th>
    //                     <th colSpan={3} style={{textAlign: 'center'}}>SIM卡</th>
    //                     <th style={{textAlign: 'center'}}>费用</th>
    //                 </tr>
    //             }
    //             fieldsInit={{
    //                 posModel: {name:'POS机型号', value(model) {
    //                         return (
    //                             <div>
    //                                 <input type="hidden" name={'device[]'} value={JSON.stringify(model)}/>
    //                                 {model.posModel}
    //                             </div>
    //                         );
    //                     }},
    //                 posDeviceNum: 'POS机编号',
    //                 posMade: 'POS厂商',
    //                 deposit: '押金',
    //                 keyboardModel: '键盘型号',
    //                 keyboardDeviceNum: '键盘编号',
    //                 keyboardMade: '键盘厂商',
    //                 simModel: 'SIM类型',
    //                 simDeviceNum: 'SIM卡号',
    //                 simMade: '运营商',
    //                 money: ''
    //             }}
    //             oprationShow={false}
    //             layuiFluid={false}
    //             layuiCardBody={false}
    //             paging={false}
    //             btnShow={false}
    //             searchShow={false}
    //
    //         />
    //     )
    // }
    //
    // addDevice() {
    //     this.layOpen({
    //         title: '添加终端',
    //         content: '/admin/device/choose',
    //         form: true,
    //         area: ['900px', '95%'],
    //         yes: (form, index) => {
    //             let pos = form.pos,
    //                 keyboard = form.keybord,
    //                 sim = form.sim;
    //             if (!pos) {
    //                 return layer.msg('请选择POS机!');
    //             }
    //             if (!keyboard) {
    //                 return layer.msg('请选择键盘!');
    //             }
    //             if (!sim) {
    //                 return layer.msg('请选择SIM卡!');
    //             }
    //             pos = JSON.parse(pos);
    //             keyboard = JSON.parse(keyboard);
    //             sim = JSON.parse(sim);
    //             let devices = this.state.model.device;
    //             devices.push(
    //                 {
    //                     posId: pos.id,
    //                     posModel: pos.model,
    //                     posDeviceNum: pos.deviceNum,
    //                     posMade: pos.made,
    //                     deposit: 0,
    //                     keyboardId:keyboard.id,
    //                     keyboardModel: keyboard.model,
    //                     keyboardDeviceNum: keyboard.deviceNum,
    //                     keyboardMade: keyboard.made,
    //                     simId: sim.id,
    //                     simModel: sim.model,
    //                     simDeviceNum: sim.deviceNum,
    //                     simMade: sim.made,
    //                     money: 0
    //                 }
    //             )
    //             this.setModel({
    //                 device: devices
    //             })
    //             layer.close(index);
    //         }
    //     })
    // }
}
