import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import md5 from 'js-md5'

class AdminService extends BaseService
{
   requestUrls = {
       information:'/getInfo',
       modifyPassword:'api/updatePw',
       url:'/system/admin/list',
       save:'/system/admin/save',
       change:'/system/admin/change',
       detail:'/system/admin/detail',
       delete:'/system/admin/delete'
   }

   states = ['停用', '正常'];

   appends = ['stateDup', 'roleName'];

   leaders = ['admin'];

   save(model, suc, err) {
       var validator = new Validator(),
           rules = [
               ['username', 'required|min:3|max:12', '请填写账号|账号3-12位|账号3-12位'],
               ['password', (!model.id ? 'required|' : '') + 'min:6|max:20|confirmed', (!model.id ? '请填写密码|' : '') + '密码至少6位|密码最多20位|确认密码错误'],
               ['mobile', 'mobile', '手机号码格式不正确'],
               ['roleId', 'required|gt:0', '请选择角色|请选择角色']
           ];

       if (! validator.validate(rules, model)) {
           return err(validator.getError());
       }

       this.post(
           this.requestUrls.save,
           model,
           suc,
           err
       )
   }

   getDetail(id, suc) {
       this.post(
           this.requestUrls.detail,
           {id:id},
           suc
       )
   }

   delete(id, suc, err) {
       this.request({
           url:this.requestUrls.delete,
           data:{id:id},
           success:suc
       })
   }

   getInforamation() {
       let self = this;
       self.request({
           url:this.requestUrls.informationUrl,
           success(msg, data) {
            self.page.setState({
                model:data
            })
           }
       })
   }

    modifyPassword(field, suc, err) {
       var validator = new Validator(),
           rules = [
               ['oldPassword', 'required', '原密码不能为空'],
               ['newPassword', 'required|confirmed', '新密码不能为空|重复新密码不正确'],
           ];

       if (! validator.validate(rules, field)) {
           return err(validator.getError());
       }

       this.request({
            url:this.requestUrls.modifyPasswordUrl,
            data:{oldPassword:md5(field.oldPassword),newPassword:md5(field.newPassword)},
            success:suc,
            error:err
       })
    }

    getRoleNameAttribute() {
        let role = this.attributes['role'];
        if (role) {
            return role.name;
        }
    }

    getStateDupAttribute() {
       return this.states[Number(this.attributes['status'])];
    }

    getCanEditAttribute(val, model) {
        return this.auth.getUser('is_boss') === 1 || model.isBoss === 1;
    }

    getCanEditUsernameAttribute(val, model) {
        return model.isBoss === 1;
    }
}

export default AdminService;
