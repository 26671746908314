import Base from "../../../base";
import Form from "../../../layout/form";
import BankService from "../../../../services/running/bank";

export default class BankEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                type:'',
                account:'',
                password:'',
                status:1
            }
        }
    }

    service() {
        return BankService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'银行名称',
                    type:{name:'类型', type: 'select', data: this.server.type},
                    account:'账号',
                    password:{name:'密码', type:'password'},
                    status:{name:"状态", type:'select', data: this.server.status}
                }}
            />
        );
    }
}
