import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class LogService extends BaseService
{
    requestUrls = {
        url:'/device/logDetail',
    }
 
    status = ['未出库', '已出库'];
	deviceStatusName = ['未安装','已安装','已撤机','已更换'];
    appends = ['statusDup','deviceStatusDup'];

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
	 
	
	getDeviceStatusDupAttribute() {
	    return this.deviceStatusName[this.attributes['device_status']];
	}
	getList(id, suc) {
	    this.post(
	        this.requestUrls.url,
	        {id:id},
	        suc
	    )
	}
	
	
 
	
 
}
