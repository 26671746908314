import Service from '../../../../vendor/framework/service';
import App from "../../../../vendor/framework/app";
import Validator from "../../../../vendor/framework/validator";

class Auth extends Service {
    app = new App();

    requestUrls = {
        login: 'auth/login',
        userDetail: 'auth/user/detail'
    }

    filed = 'yvfuCompanyToken';
    lastUrl = 'yvfuCompanyLastUrl';
    path = '/admin/login';
    rememberKey = 'yvfuCompanyRemember';
    historyKey = 'yvfuCompanyHistory';
    userKey = 'yvfuCompany';
    ruleKey = 'yvfuCompanyRules';

    signIn(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['account', 'required', '请输入用户名'],
                ['password', 'required', '请输入密码']
            ];
        if (!validator.validate(rules, model)) {
            return err(validator.getError());
        }
        if (!model.jigsawFlag) {
            return err('滑块错误!');
        }

        this.request({
            url: this.requestUrls.login,
            data: {username: model.account, password: model.password},
            toHump:false,
            success: (res) => {
                if (model.keepState) {
                    model.accountDup = model.account;
                    this.setRemember(model);
                    this.setHistory(model);
                } else {
                    this.removeRemember();
                    this.removeHistory(model.account);
                }

                this.login(res);
                this.setUser(res.user);
                suc(res.user);
            },
            error: err
        })
    }

    setRules(data) {
        this.app.setItem(this.ruleKey, JSON.stringify(data));
    }

    check() {
        return Boolean(this.getToken());
    }

    login(data) {
        return this.app.setItem(this.filed, data.token);
    }

    logout() {
        return this.app.removeItem(this.filed);
    }

    getToken() {
        return this.app.getItem(this.filed);
    }

    setUser(user) {
        this.app.setItem(this.userKey, JSON.stringify(user));
    }

    getUser(key) {
        let user = this.app.getItem(this.userKey);
        if (user) {
            user = JSON.parse(user);
            if (key) {
                return user[key];
            }
            return user;
        }
    }

    getLastUrl(defaultUrl) {
        return this.app.pullItem(this.lastUrl) || defaultUrl;
    }

    noPassByMobile(mobile) {
        if (mobile) {
            var pat = /(\d{3})\d*(\d{4})/;
            return mobile.replace(pat, '$1****$2');
        }
    }

    getRemember(suc) {
        let val = this.app.getItem(this.rememberKey);
        if (val) {
            suc(JSON.parse(val));
        }
    }

    setRemember(data) {
        this.app.setItem(this.rememberKey, JSON.stringify(data));
    }

    removeRemember() {
        this.app.removeItem(this.rememberKey);
    }

    getHistoryList() {
        let list = this.app.getItem(this.historyKey);
        if (list) {
            return JSON.parse(list);
        }
    }

    setHistory(secrets = {}) {
        let list = this.getHistoryList() || {};
        if (secrets.account && !list.hasOwnProperty(secrets.account)) {
            list[secrets.account] = secrets;
            this.app.setItem(this.historyKey, JSON.stringify(list));
        }
    }

    removeHistory(account) {
        let list = this.getHistoryList();
        if (list && list.hasOwnProperty(account)) {
            delete list[account];
            if (JSON.stringify(list) === '{}') {
                this.app.removeItem(this.historyKey);
            } else {
                this.app.setItem(this.historyKey, JSON.stringify(list));
            }
        }
    }

    getLikeHistory(number) {
        let list = this.getHistoryList(),
            data = [];
        if (list) {
            for (var k in list) {
                if (k.indexOf(number) >= 0) {
                    data.push(list[k]);
                }
            }
        }
        return data;
    }
}

export default Auth;
