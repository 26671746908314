import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import OrderMaintainingService from "../../../../services/order/maintaining";
import $ from "jquery";
import Excel from "../../../../components/excel";
import excelLayout from "./excelLayout";
import File from "../../../../../../vendor/framework/file";

export default class OrderMaintainingListLayout extends View
{
    componentDidMount() {
        /* global layer */
    }

    service() {
        return OrderMaintainingService;
    }

    render() {
        return (
            <Table
                props={this.props}
                canCheck={true}
                checkMany={true}
                service={OrderMaintainingService}
                stateInit={{
                    orderBy:['id', 'desc']
                }}
                searchInit={{
					keywords: {name: '关键字', placeholder:'商户编号/工单编号/商户名称/终端号/负责人', enter: true},
                    company:{name:'所属公司', type:'component', component: this.buttonComponent.bind(this)},
                    orderType:{name:'工单类型', key:'order_type', type:'select', data: this.server.orderTypes, default:''},
                    status:{name:'状态', type:'select', data: this.server.status, default:''},
					dateMin: {name: '添加时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间'},
					dateMax: {type: 'date', position: 1,format:'datetime', placeholder: '结束时间'},
					dateMin1:{name: '完成时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间',field:'served_at',key:"dateMin"},
					dateMax1:{type: 'date', position: 1, format:'datetime',placeholder: '结束时间',field:'served_at',key:"dateMax"},
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    orderSn:'工单编号',
                    orderTypeDup:'工单类型',
                    terminalCode:'终端号',
                    company:{name:'所属公司', value(model) { return model.client && model.client.company ? model.client.company.name : ''}},
                    client:{name:'商户', value(model) { return model.client ? model.client.name : ''}},
                    clientCode:{name:'商户编号', value(model) { return model.client ? model.client.code : ''}},
                    companyUser:{name:'负责人', value(model) { return model.companyUser ? model.companyUser.realname : ''}},
                    visitedAt:'上门时间',
                    servedAt:'完成时间',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)},
                    check:{name:'指派', click: this.check.bind(this)},
                    manyAppointLayout:{name:'批量指派模板下载', click: this.manyAppointLayout.bind(this)},
                    manyAppointImport:{name:'批量指派', click: this.manyAppointImport.bind(this)},
                    excelLayout: {name: 'EXCEL模板下载', className: 'layui-btn-warm', click: this.excelLayout.bind(this)},
                    import:{name:'导入', className:'layui-btn-normal', click: this.import.bind(this)},
                    export:{name:'导出', click: this.export.bind(this)},
                    download:{name:'下载', click: this.download.bind(this)},
                }}
                oprationInit={{
                    detail:{name:'详情', icon:['list', 'normal'], click:this.detail.bind(this)},
                    edit:{name:'修改', icon:['edit', 'normal'], click:this.edit.bind(this)},
                    serve:{name:'指派', icon:['set', 'warm'], disabled:model => { return model.status; }, click: this.serve.bind(this)},
                    audit:{name:'审核', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => { return model.status !== 1; }, click:this.audit.bind(this)},
                    sureAdd:{name:'确认入库', icon:['sureAdd', 'warm', 'iconfont icon-shenhe'], disabled: model => { return (model.sureAdd==1 || model.status !=3); }, click:this.sureAdd.bind(this)},
					delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        );
    }

    buttonComponent(table) {
        return (
            <button className={"layui-btn"} onClick={e => this.chooseCompany(table)}>{ table.state.companyName || '所有公司'}</button>
        )
    }

    add(table) {
        this.layOpen({
            title:'添加工单',
            content:'/admin/order/maintaining/add',
            form:true,
            area:['80%', '98%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改工单'+model.orderSn,
            content:'/admin/order/maintaining/edit/'+model.id,
            form:true,
            area:['80%', '98%'],
            yes: (field, index) => {
                field.id = model.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    detail(model) {
        this.layOpen({
            title:'工单详情'+model.orderSn,
            content:'/admin/order/maintaining/detail/'+model.id,
            area:['80%', '98%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    chooseCompany(table) {
        this.layOpen({
            title:'选择公司',
            content:'/admin/company/choose/list',
            form: true,
            area:['900px', '90%'],
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;

                let model = data && data.length ? JSON.parse(data[0]) : {id:'', name:''};
                table.setState({
                    companyName:model.name,
                })
                table.setSearch({
                    'client.company_id':model.id
                })
                table.loadData();
                layer.close(index);
            }
        })
    }

    serve(model, table) {
        this.layOpen({
            title:'选择撤机人',
            content:'/admin/company/user/choose/list',
            form:true,
            area:['80%', '90%'],
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                form.ids = [model.id];
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择负责人!');
                }
                form.cuid = JSON.parse(data[0]).id;
                table.server.serve(form, () => {
                    layer.msg('指派成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除工单号为 ' + model.orderSn + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    check(table) {
        let ids = [],
            data = [];
        $('input[type=checkbox]').each(function() {
            if ($(this).prop('checked')) {
                let val = JSON.parse($(this).val());
                data.push(val);
            }
        })

        for(let k of data) {
            if (k.status) {
                return layer.msg('工单号 ' +k.orderSn+' 已完成,不可再指派!');
            }
            ids.push(k.id);
        }

        if (! ids.length) {
            return layer.msg('请选择要指派的工单!');
        }
        this.layOpen({
            title:'选择指派人',
            content:'/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('选择指派人!');
                }
                let user = JSON.parse(data[0]);
                let model = {
                    ids:ids,
                    cuid:user.id
                }
                table.server.serve(model, () => {
                    layer.msg('已指派!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) =>{
                    layer.msg(msg);
                })
            }
        })
    }

    audit(model, table) {
        layer.confirm('审核结果', {icon: 3, title:'提示',btn:['通过', '拒绝']}, () => {
            audit(3);
        },() => {
            audit(2);
        })
        function audit(status) {
            table.server.audit({id:model.id,status:status}, () => {
                table.pageReload();
                layer.msg('已审核!');
            }, (msg) => {
                layer.msg(msg);
            })
        }
    }
	
	sureAdd(model, table) {
	    layer.confirm('您真的要工单号为 ' + model.orderSn + '已完成，确认入库吗?', () => {
	        table.server.sureAdd(model.id, () => {
	            layer.msg('入库成功!');
	            table.pageReload();
	        }, (msg) => {
	            layer.msg(msg);
	        })
	    })
	}
	
	
	

    manyAppointLayout() {
        let keys = ['id', 'orderSn', 'checkUser'],
            arr = [],
            data = [
                {
                    'id':'1',
                    'orderSn':' 2022010211582210 ',
                    'checkUser':'志星',
                }
            ];
        arr[0] = ['序号', '维护工单编号', '维护员工'];
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('批量指派模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:C1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    manyAppointImport(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/maintaining/import/appoint',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.manyAppoint(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    excelLayout() {
        let keys = Object.keys(this.server.excelFields),
            arr = [],
            data = excelLayout;
        arr[0] = this.server.getExcelTitles();
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('维护导入模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:N1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    import(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/maintaining/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.import(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }


    //export(type = 0) {
		export(table, type = 0) {
        let ids = this.getCheckIds();
        if (! ids.length) {
            return layer.msg('请选择数据!');
        }
        this.server.export({ids:ids, type: type}, res => {
            if (type) {
                let url = (new File()).make(res.path);
                window.open(url);
                return;
            }
            let list = [];
            res.forEach(item => {
                if (ids.indexOf(item.id) > -1) {
                    list.push(item);
                }
            })
            let titles = this.server.getExcelTitles();
            titles.push("结果");
            titles.push("理由");
			titles.push("机身号");
            let keys = Object.keys(this.server.excelFields),
                data = [titles];
            keys.push("result1");
            keys.push("reason");
			keys.push("posDeviceNum");
			
            list.forEach(item => {
                let arr = [],
                    client = item.client || {},
                    company = client.company  || {},
                    checkUser = item.companyUser || {},
                    row = {
                        id:item.id,
                        orderSn:item.orderSn,
                        type:item.typeDup,
                        device:item.orderType === 0 ? item.deviceTypeDup : '',
                        company:company.name || '',
                        clientName:client.name || '',
                        clientCode:client.code || '',
                        username:item.username || '',
                        phone:item.phone || '',
                        address:item.address || '',
                        terminalCode:item.terminalCode,
                        checkUser:checkUser.realname || '',
                        result:item.statusDup,
                        servedAt:item.servedAt,
                        result1:item.resultDup,
                        reason:item.reason,
						posDeviceNum:item.posDeviceNum
                    }
                keys.forEach(field => {
                    arr.push(row[field] || '');
                })
                data.push(arr);
            })
			
			console.log(data);

            let excel = new Excel();
            excel.export('维护工单列表', data, (workbook, table) => {
                workbook.sheet(0).row(1).height(50);
                workbook.sheets().forEach((sheet) => {
                    // 表头加粗及背景色
                    sheet.range('A1:P1').style({
                        wrapText: true,
                        fill: "00bdf0",
                        bold: true,
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                        fontSize: 12,
                        border: {
                            style: 'thin',
                            color: "000000",
                            direction: "both"
                        }
                    });

                    for (let k in table[0]) {
                        sheet.column(k).width(25);
                    }
                })
            })
        })
    }

    download() {
        this.export(null,1);
    }
}
