import BaseService from "../base";
import File from "../../../../vendor/framework/file";
import Validator from "../../../../vendor/framework/validator";

class UserService extends BaseService
{
    requestUrls = {
        url:'/user/list',
        save:'/user/save',
        detail:'/user/detail',
        change:'/user/change',
        audit:'/user/audit',
        import:'/user/import',
		export:'/user/export'
    }

    status = {
        '-1':'禁用',
        '0':'未申请',
        '1':'申请中',
        '2':'正常'
    };

    auditStatus = ['待审核', '审核通过', '审核拒绝'];

    isCompany = ['否', '是'];

    appends = ['image', 'position', 'statusDup', 'auditStatusDup', 'qrcode'];

    file = new File();

    excelFields = {
        id:'序号',
        name:'商户名称',
        code:'商户编码',
        phone:'登录手机',
        company:'所属公司',
        type:'类型',
        attr:'属性',
        province:"省",
        city:'市',
        area:'区',
        address:'详细地址',
        bankAddress:'银行商户地址',
        receiveBank:'收单银行',
        bank:'开户银行',
        lendRate:'费率(借)',
        loanRate:'费率(贷)',
        outRate:'费率(外)',
        originRate:'费率(本)',
        specialCoast:'特殊计费',
        level:'商户等级',
        expansion:'拓展人',
        expansionCoast:'拓展费用',
        platform:'所属平台',
        bankAgent:'银行经办人',
        expansionType:'拓展方式',
        channel:'渠道',
        transactionControl:'交易控制',
        receiveDate:'收单日期',
        postcode:'邮政编码',
        serialNumber:'申请序号',
        risk:'风险等级',
        corporate:'商户法人',
        corporatePhone:'法人手机',
        corporateTel:'法人固话',
        corporateFax:'法人传真',
        corporateIdcode:'法人证件号',
        corporateValidityStartDate:'法人证件有效期开始',
        corporateValidityEndDate:'法人证件有效期结束',
        contact:'联系人',
        tel:'联系方式',
        salesman:'业务员',
        salesmanNum:'业务员工号',
        externalChannels:'外部渠道',
        clientManagerPhone:'客户经理电话',
        district:'区域',
    }

    getExcelTitles() {
        let titles = [];
        Object.keys(this.excelFields).forEach(item => {
            titles.push(this.excelFields[item]);
        })
        return titles;
    }

    save(model ,suc, err) {
        let rules = [
            ['code', 'required', '请填写商户编号'],
            ['name', 'required', '请填写商户名称'],
            ['phone', 'required|mobile', '请填写登录手机号码|手机号码不正确'],
            ['typeId', 'required|gt:0', '请选择类型|请选择类型'],
            ['attrId', 'required|gt:0', '请选择属性|请选择属性'],
            ['province', 'required', '请选择省'],
            ['city', 'required', '请选择市'],
            ['area', 'required', '请选择区'],
            ['longitude', 'required', '请选择详细地址'],
            ['latitude', 'required', '请选择详细地址'],
            ['address', 'required', '请填写详细地址'],
            ['companyId', 'requiredIf:isCompany,1', '请选择集团'],
            ['receiveBankId', 'required', '请选择收单银行'],
            ['bankId', 'required', '请选择开户银行'],
            ['facadeImg', 'required', '请上传门面照'],
            ['corporate', 'required', '请填写法人代表'],
            ['corporatePhone', 'required', '请填写法人电话'],
            ['contact', 'required', '请填写联系人'],
            ['tel', 'required', '请填写联系电话'],
        ];

        if (! this.validate(rules, model)) {
            return err(this.validator.getError());
        }
        if (model.facadeImg) {
            for(let k in model.facadeImg) {
                model.facadeImg[k] = this.file.filter(model.facadeImg[k]);
            }
        }
        if (model.qrcode) {
            model.qrcode = this.file.filter(model.qrcode);
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDeviceDetail(id, suc) {
        this.post(
            this.requestUrls.deviceDetail,
            {id:id},
            suc
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    changeStatus(id, suc, err) {
        this.post(
            this.requestUrls.change,
            {id:id},
            suc,
            err
        )
    }

    audit(model, suc, err) {
        this.post(
            this.requestUrls.audit,
            model,
            suc,
            err
        )
    }

    import(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.code', 'required', '请填写商户编号'],
                ['data.*.name', 'required', '请填写商户名称'],
                ['data.*.phone', 'required', '请填写登录手机'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.import,
            data,
            suc,
            err
        )
    }

    getImageAttribute() {
        let information = this.attributes['information'];
        if (information && information.facadeImg) {
            let images = information.facadeImg.split(',');
            return this.file.make(images[0]);
        }
        return '';
    }

    getPositionAttribute() {
        let information = this.attributes['information'];
        if (information) {
            return information.province + information.city + information.area;
        }
        return '';
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getAuditStatusDupAttribute() {
        let information = this.attributes['information'];
        if (information) {
            return this.auditStatus[information.status];
        }
        return '';
    }

    getQrcodeAttribute() {
        let information = this.attributes['information'];
        if (information && information.qrcode) {
            let image = information.qrcode;
            return this.file.make(image);
        }
        return '';
    }
	
	
	
	excelOutFields = {
	    id:'序号',
	    name:'商户名称',
	    code:'商户编码',
	    phone:'登录手机',
	    company:'所属公司',
	    type:'类型',
	    attr:'属性',
	   unSend:'待派装机',
	   sent:'已派装机',
	   installed:'已装机',
	   unrepeal:'已派撤机',
	   repealed:'已撤机',
	   statusDup:'账号状态',
	   auditStatusDup:'审核状态',
	   createdAt:'注册时间',
	    province:"省",
	    city:'市',
	    area:'区',
	    address:'详细地址',
	    bankAddress:'银行商户地址',
	    receiveBank:'收单银行',
	    bank:'开户银行',
	    lendRate:'费率(借)',
	    loanRate:'费率(贷)',
	    outRate:'费率(外)',
	    originRate:'费率(本)',
	    specialCoast:'特殊计费',
	    level:'商户等级',
	    expansion:'拓展人',
	    expansionCoast:'拓展费用',
	    platform:'所属平台',
	    bankAgent:'银行经办人',
	    expansionType:'拓展方式',
	    channel:'渠道',
	    transactionControl:'交易控制',
	    receiveDate:'收单日期',
	    postcode:'邮政编码',
	    serialNumber:'申请序号',
	    risk:'风险等级',
	    corporate:'商户法人',
	    corporatePhone:'法人手机',
	    corporateTel:'法人固话',
	    corporateFax:'法人传真',
	    corporateIdcode:'法人证件号',
	    corporateValidityStartDate:'法人证件有效期开始',
	    corporateValidityEndDate:'法人证件有效期结束',
	    contact:'联系人',
	    tel:'联系方式',
	    salesman:'业务员',
	    salesmanNum:'业务员工号',
	    externalChannels:'外部渠道',
	    clientManagerPhone:'客户经理电话',
	    district:'区域',
	}
	getExcelOutTitles() {
	    let titles = [];
	    Object.keys(this.excelOutFields).forEach(item => {
	        titles.push(this.excelOutFields[item]);
	    })
	    return titles;
	}
	
	export(data, suc) {
	    this.post(
	        this.requestUrls.export,
	        data,
	        suc
	    )
	}
	
	
	
	
	
}
export default UserService;
