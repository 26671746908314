import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import CompanyUserService from "../../../../services/company/user";

export default class CompanyUserListLayout extends View
{
    boot() {
        this.state = {
            companyName:'',
            orderBy:['id', 'desc']
        }
    }

    service() {
        return CompanyUserService;
    }

    render() {
        return (
            <Table
                props={this.props}
                service={CompanyUserService}
                stateInit={this.state}
                searchInit={{
                    keywords:{name:'关键字', enter:true},
                    status:{name:'状态', type:'select', data:this.server.status, default:''},
                    dateMin:{name:'添加时间',  type:'date', position:0, format:'datetime', placeholder:'开始时间'},
                    dateMax:{type:'date', position:1, format:'datetime',placeholder:'结束时间'},
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    // portrait:{name:'头像', type:'image'},
                     username:'员工账号',
                    realname:'姓名',
                    phone:'手机号码',
                    locateModeDup:'定位方式',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)},
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    locationList: {name:'位置信息', icon:['log'], click: this.locationList.bind(this)}
                }}
            />
        );
    }

    componentDidMount() {
        /* global layer */
    }

    add(table) {
        this.layOpen({
            title:'添加员工',
            content:'/admin/company/user/add',
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title:'修改 '+val.realname,
            content:'/admin/company/user/edit/'+val.id,
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                field.id = val.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.name + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    locationList(model) {
        this.layOpen({
            title:model.realname+'的位置记录',
            content:'/location.html?uid='+model.id,
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}
