import Base from "../../../base";
import PosListLayout from "../layout";

export default class PosChooseList extends Base
{
    render() {
        return (
            <PosListLayout
                canCheck={true}
                btnShow={false}
                oprationShow={false}
            />
        );
    }
}
