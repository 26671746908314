import RoleService from "../../../../services/admin/role";
import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";

class RoleListLayout extends View
{

    boot() {
        this.state = {
            list:[]
        }
    }

    render() {
        return (
            <Table
                service={RoleService}
                props={this.props}
                paging={true}
                fieldsInit={{
                    id:{name:'ID', type:'text', checkbox: true},
                    name:'名称',
                    descDup:'描述',
                    stateDup:'状态',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:(table) => { this.add(table); }},
                }}
                oprationInit={{
                    edit:{name:'修改', icon:['edit', 'normal'], click:this.edit.bind(this)},
                    delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        )
    }

    service() {
        return RoleService;
    }

    componentDidMount() {
        /* global layer */

    }

    add(table) {
        this.layOpen({
            title:'添加角色',
            content:'/admin/role/add',
            form:true,
            yes: (field, index) => {
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title:'修改角色',
            content:'/admin/role/edit/'+val.id,
            form:true,
            yes: (field, index) => {
                field.id = val.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.name + ' 这个角色吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default RoleListLayout;
