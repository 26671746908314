import Base from "../../../base";
import BankListLayout from "../layout";

export default class BankChooseList extends Base
{
    render() {
        return (
            <BankListLayout
                canCheck={true}
                oprationShow={false}
                searchFields={['keywords', 'type']}
                btnShow={false}
            />
        );
    }
}
