import UserListLayout from "../layout/list";
import Base from "../../base";

class UserList extends Base
{
	render() {
		return (
			<UserListLayout
				searchShow={true}
				paging={true}
				layForm={true}
				canCheck={true}
				checkMany={true}
				 />
		);
	}
}

export default UserList;
