import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class OrderInstallService extends BaseService
{
    requestUrls = {
        url:'/order/install/list',
        save:'/order/install/save',
        delete:'/order/install/delete',
        detail:'/order/install/detail',
        setInstallUser:'/order/install/set-install',
        import:'/order/install/import',
        export:'/order/install/export',
        manyInstall:'/order/install/many-install/import',
        audit:'/order/install/audit'
    }

    status = ['未安装', '审核中', '审核拒绝', '已安装', '已撤机'];

    type = ['直联单', '间联单'];

    result = ['异常', '正常'];

    appends = ['statusDup', 'typeDup', 'resultDup'];

    excelFields = {
        'id':'序号',
        'orderSn':'工单编号',
        'type':'工单类型',
        'company':'所属公司',
        'clientCode':'商户编号',
        'clientName':'商户名称',
        'corporate':'商户法人',
        'corporatePhone':'法人手机',
        'corporateTel':'法人固话',
        'corporateFax':'法人传真',
        'corporateIdcode':'法人证件号',
        'corporateValidityStartDate':'法人证件有效期开始',
        'corporateValidityEndDate':'法人证件有效期结束',
        'username':'联系人',
        'phone':'联系电话',
        'address':'安装地址',
        'district':'区域',
        'contractor':'承包人',
        'terminalCode':'终端号',
        'receiveBank':'收单银行',
        'bank':'开户银行',
        'lendRate':'费率(借)',
        'loanRate':'费率(贷)',
        'outRate':'费率(外)',
        'originRate':'费率(本)',
        'specialCoast':'特殊计费',
        'level':'商户等级',
        'expansion':'拓展人',
        'expansionCoast':'拓展费用',
        'platform':'所属平台',
        'bankAgent':'银行经办人',
        'expansionType':'拓展方式',
        'channel':'渠道',
        'transactionControl':'交易控制',
        'receiveDate':'收单日期',
        'postcode':'邮政编码',
        'serialNumber':'申请序号',
        'risk':'风险等级',
        'authorized':'被授权人',
        'authorizedIdcode':'被授权人身份证',
        'authorizedValidityStartDate':'被授权人身份证有效期开始',
        'authorizedValidityEndDate':'被授权人身份证有效期结束',
        'registerDate':'登记日期',
        'startDate':'启用日期',
        'aliCoastRate':'支付宝费率',
        'wechatCoastRate':'微信费率',
        'posModel':'POS机型号',
        'posDeviceNum':'POS机设备号',
        'posMade':'POS机厂商',
        'keyboardModel':'键盘型号',
        'keyboardDeviceNum':'键盘设备号',
        'keyboardMade':'键盘厂商',
        'simModel':'SIM卡型号',
        'simDeviceNum':'SIM卡号',
        'simMade':'SIM卡运营商',
        'result':'是否安装成功',
        'installUser':'安装员',
        'visitedAt':'上门时间',
        'servedAt':'完成时间',
        'salesman':'业务员',
        'salesmanNum':'业务员工号',
        'externalChannels':'外部渠道',
        'clientManagerPhone':'客户经理电话',
    };

    checkFields = {
        ID:'序号',
        orderSn:'工单编号',
        installUser:'安装员工'
    }

    getExcelTitles() {
        let titles = [];
        Object.keys(this.excelFields).forEach(item => {
            titles.push(this.excelFields[item]);
        })
        return titles;
    }

    save(model, suc, err) {
        let rules = [
                ['uid', 'required', '请选择商户'],
            ],
            validator = new Validator();
        model = this.app.htmlArrayToJson(model);

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }

    setInstallUser(data, suc, err) {
        this.post(
            this.requestUrls.setInstallUser,
            data,
            suc,
            err
        )
    }

    import(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.type', 'required|in:间联单,直联单', '请填写工单类型|工单类型错误(间联单,直联单)'],
                ['data.*.company', 'required', '请填写所属公司'],
                ['data.*.clientCode', 'required', '请填写商户编号'],
                ['data.*.clientName', 'required', '请填写商户名称'],
                ['data.*.corporate', 'required', '请填写商户法人'],
                ['data.*.corporatePhone', 'required', '请填写法人手机'],
                ['data.*.username', 'required', '请填写联系人'],
                ['data.*.phone', 'required', '请填写联系电话'],
                ['data.*.address', 'required', '请填写安装地址'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.import,
            data,
            suc,
            err
        )
    }

    export(data, suc) {
        this.post(
            this.requestUrls.export,
            data,
            suc
        )
    }

    manyInstall(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.orderSn', 'required', '请填写工单编号'],
                ['data.*.installUser', 'required', '请填写安装人'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.manyInstall,
            data,
            suc,
            err
        )
    }

    audit(model, suc, err) {
        this.post(
            this.requestUrls.audit,
            model,
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getTypeDupAttribute() {
        return this.type[this.attributes['type']];
    }

    getResultDupAttribute() {
        return this.attributes['status'] !== 0 ? this.result[this.attributes['result']] : '';
    }
}
