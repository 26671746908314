import Base from "../../../base";
import Detail from "../../../layout/detail/detail";
import OrderInstallService from "../../../../services/order/install";
import File from "../../../../../../vendor/framework/file";
import UserDeviceListLayout from "../../../user/device/layout";

export default class OrderInstallDetail extends Base
{
    file = new File();

    boot() {
        this.state = {
            model:{
            },
            terminal:[]
        }
    }

    service() {
        return OrderInstallService;
    }

    componentDidMount() {
        /* global layer */
        this.getDetail();
    }

    async getDetail() {
        await this.server.getDetail(this.getPropsParams('id'), data => {

            if (! data.device) {
                data.device = {};
            }

            data.terminalCode =  data.device.terminalCode;
            if (data.device.id) {
                this.setState({
                    terminal:[data.device]
                })
            }
            this.setModel(data);
        })
    }

    render() {
        return (
            <Detail
                state={this.state}
                fields={[
                    {
                        title:'工单信息',
                        fields:{
                            orderSn:'工单号',
                            typeDup:'类型',
                            username:'联系人',
                            phone:'联系电话',
                            address:'地址',
                            remark:{name:'备注(问题描述)', block:true},
                            visitedAt:'上门时间',
                            servedAt:'完成时间',
                            createdAt:'添加时间',
                            notes:{name:'员工备注',block: true},
                            statusDup:'状态',
                            auditReason:{name:'审核理由', show: model => model.auditReason }
                        }
                    },
                    {
                        title:'商户信息',
                        fields:{
                            'client.company.name':'所属公司',
                            'client.name':'商户名称',
                             detail:
                                 {name:'详情',
                                     type :'itemComponent',
                                     component: () => {
                                         return (
                                             <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient()}>
                                                <i className="layui-icon layui-icon-list"></i>
                                                查看
                                             </button>
                                         )
                                }
                            },
                        }
                    },
                    {
                        title:'安装/巡检',
                        fields:{
                            'installUser.realname':'安装人',
                             installBtn:{
                                name:'安装人信息',
                                 show: model => {
                                    return model.installUid;
                                 },
                                 type:'itemComponent',
                                 component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showInstallUser()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                 }
                            },
                            hr:{name:'', block: true},
                        }
                    },
                    {
                        title:'安装信息',
                        show:model => {
                            return model.status !== 0;
                        },
                        fields:{
                            facadeImg:{name:'门面照', type:'slide', slide: 'slide'},
                            innerImg:{name:'室内照', type:'slide', slide: 'slide'},
                            deskImg:{name:'前台照', type:'slide', slide: 'slide'},
                            physicalImg:{name:'机身照', type:'slide', slide: 'slide'},
                            //staticImg:{name:'静态照', type:'slide', slide: 'slide'},
                            salesImg:{name:'签购单', type:'slide', slide: 'slide'},
							signImg:{name:'签名照', type:'slide', slide: 'slide'},
                            result:{name:'结果', value: model => { return this.server.result[model.result] }, block: true},
                            reason:{name:'备注原因', block: true},
							posDeviceNum:{name:'机身号', block: true}
                        }
                    },
                    {
                        title:'终端信息',
                        fields:{
                            terminal: {
                                name: '终端',
                                type: 'itemComponent',
                                component: this.terminal.bind(this),
                                block: true,
                            },
                        }
                    }
                ]}
            />
        );
    }

    terminal() {
        return (
            <UserDeviceListLayout
                autoload={false}
                show={this.state.terminal.length}
                list={this.state.terminal}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'terminalId'}
                visibles={['id', 'clientImage', 'client.code', 'client.name', 'terminalCode', 'client.bankAddress','deviceRemark']}
            />
        )
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['900px', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    showInstallUser() {
        let installUser = this.state.model.installUser;
        this.layOpen({
            title:'查看安装人' + installUser.realname,
            content:'/admin/company/user/detail/'+ installUser.id,
            area:['800px', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }

    showCheckUser() {
        let checkUser = this.state.model.checkUser;
        this.layOpen({
            title:'查看巡检人' + checkUser.realname,
            content:'/admin/company/user/detail/'+ checkUser.id,
            area:['800px', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }
}
