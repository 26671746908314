import App from "./app";
import React from 'react';
import Collection from './collection';
import Request from "./request";
import Loading from "../utils/loading";
import Validator from "./validator";

class Service extends React.Component
{
    app = new App();

    view;

    attributes = [];

    makeModel = true;

    subjoinAll = true;

    validator = new Validator();

    toHump = true;

    constructor(view) {
        super(view);
        this.view = view;
        if (this.boot && typeof this.boot === 'function') {
            this.boot(view);
        }
    }

    makeAttributes(data) {
        this.attributes = data;
        return this.handleAttributes(this.attributes);
    }

    handleAttributes(model) {
        var data = {};
        for(var k in model) {
            data[k] = model[k];
        }

        if (['object', 'array'].indexOf(typeof model) >= 0) {
            for(var j in model) {
                var func = this.getFuncRule(j);
                if (this[func] && typeof this[func] === 'function') {
                    data[j] = this[func](model[j], model);
                }
            }
            if (['object', 'array'].indexOf(typeof this.appends) >= 0) {
                this.appends.forEach(item => {
                    let func = this.getFuncRule(item);
                    if (this[func] && typeof this[func] === 'function') {
                        data[item] = this[func](item, model);
                    }
                })
            }
        }
        if (this.subjoinAll) {
           data = this.subjoin(data);
        }
        return data;
    }

    getFuncRule(name) {
        return 'get' + (name.charAt(0).toUpperCase() + name.slice(1)) + 'Attribute';
    }

    subjoin(data) {
        var funcs = this.methods;
        if (funcs && typeof funcs === 'function') {
            funcs = funcs.bind(this);
            let values = funcs(data);
            for(var k in values) {
                data[k] = values[k];
            }
        }
        return data;
    }

    makeAppends(arr) {
        this.appends = arr;
        return this;
    }

    getAttributes() {
        return this.attributes;
    }

    getService(service, page) {
        return new service(page);
    }

    makeCollection(list) {
        var collection = new Collection(this, list);
        return collection.getItems();
    }

    request(conf = {}) {
         let axios = conf.axios !== undefined ? conf.axios : true,
              request = new Request(axios),
             loading = new Loading(conf.loading);
             conf.data = conf.data || {};
             conf.headers = conf.headers || request.headers;

         if (this.auth && this.auth.check()) {
             conf.headers.token = this.auth.getToken();
         }
         loading.start();
         request.setConfig(conf).send().then((response) => {
             loading.close();
             var data = response.data;
             conf.response && conf.response(data, response);
             if (data.data) {
                 data.data = ((conf.toHump === undefined && this.toHump) || conf.toHump) ? this.app.jsonToHump(data.data) : data.data;
             }
             if (data.code === this.app.config.request.successCode) {
                 var isMakeModel = conf.makeModel !== undefined ? conf.makeModel : this.makeModel;
                 if (Boolean(isMakeModel)) {
                     if (data.data && typeof data.data === 'object') {
                         if (data.data && data.data.total && data.data.data instanceof Array) {
                             data.data.list = this.makeCollection(data.data.data);
                         } else if (data.data instanceof Array) {
                             data.data = this.makeCollection(data.data);
                         } else {
                             data.data = this.makeAttributes(data.data);
                         }
                     }
                 }
                 conf.success && conf.success(data.data, data, response);
             } else {
                 if (this.app.config.request.notLoginCode.indexOf(data.code) > -1) {
                     if (typeof conf.notLogin === 'function') {
                         conf.notLogin(data);
                     } else if (typeof this.notLogin === 'function') {
                         this.notLogin(data);
                     }
                 }else if (this.app.config.request.notRuleCode.indexOf(data.code) > -1){
                     if (typeof this.notRule === 'function') {
                         this.notRule(data);
                     }
                 } else {
                     conf.error && conf.error(data.message);
                 }
             }
         }, (response) => {
             loading.close();
             conf.xerror && conf.xerror(response);
         })
    }

    post(url, data, suc, err, response) {
        this.request({
            url:url,
            data:data,
            success:suc,
            error:err,
            response:response
        })
    }

    get(url, data, suc, err) {
        this.request({
            url:url,
            method:'get',
            data:data,
            success:suc,
            error:err
        })
    }

    validate(rules ,data) {
        return this.validator.validate(rules, data);
    }
}
export default Service;
