import Base from "../../../base";
import OrderInstallService from "../../../../services/order/install";
import Form from "../../../layout/form";
import UserListLayout from "../../../user/layout/list";
import CompanyUserListLayout from "../../../company/user/layout";
import UserService from "../../../../services/user";
import BankListLayout from "../../../running/bank/layout";
import BankService from "../../../../services/running/bank";
import UserDeviceListLayout from "../../../user/device/layout";
import CompanyUserService from "../../../../services/company/user";

export default class OrderInstallAdd extends Base {
    boot() {
        this.state = {
            model: {
                uid: 0,
                username:'',
                phone:'',
                address:'',
                remark:'',
				adremark:'',
                installUid:0,
                checkUid:0,
                type:1,
                status:0,
				image:[],
            },
            client: [],
            installUser:[],
            checkUser:[],
            receiveBank:[],
            bank:[],
            terminal:[]
        }
    }

    componentDidMount() {
        /* global layer */
        let uid = this.app.getUrlParams('uid');
        if (uid) {
            this.server.getService(UserService).getDetail(uid, user => {
                this.setState({
                    client: [user]
                })
            })
        }

    }

    service() {
        return OrderInstallService;
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    base: {
                        type: 'group',
                        name: '基础信息',
                        fields: {
                            client: {
                                name: '商户',
                                type: 'choose',
                                component: this.client.bind(this),
                                click: this.chooseClient.bind(this)
                            },
                            type: {name: '单据类型', type: 'select', data: this.server.type},
                            username:'联系人',
                            phone:'联系电话',
                            address:{name:'安装地址', type:'textarea'},
                            remark:{name:'备注(问题描述)', type:'textarea'},
                            visitedAt:{name:'上门时间', type:'date', format:'datetime'},
                            installUser:{
                                name:'安装人',
                                type:'choose',
                                component: this.installUser.bind(this),
                                click: this.chooseInstallUser.bind(this)
                            },
                            status:{
                                name:'状态',
                                type:'select',
                                data:this.server.status
                            },
							
							
							
							
							
                        }
                    },
                    pos: {
                        type: 'group',
                        name: '终端信息',
                        fields: {
                            terminal: {
                                name: '终端',
                                type: 'choose',
                                component: this.terminal.bind(this),
                                click: this.chooseTerminal.bind(this)
                            },
                        }
                    },
                }}
            />
        );
    }

    terminal() {
        return (
            <UserDeviceListLayout
                autoload={false}
                show={this.state.terminal.length}
                list={this.state.terminal}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'terminalId'}
                visibles={['id', 'clientImage', 'client.code', 'client.name', 'terminalCode', 'client.bankAddress','deviceRemark']}
            />
        )
    }

    chooseTerminal() {
        let client = this.state.client[0];
        if (!client) {
            return layer.msg('请先选择商户!');
        }
        this.layOpen({
            title: '选择终端',
            content: '/admin/user/device/choose/list?uid='+client.id+'&status=0',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择终端');
                }
                let terminal = JSON.parse(data[0]);
                if (terminal.contractor) {
                    this.server.getService(CompanyUserService).searchList(terminal.contractor, list => {
                        if (list.data.length) {
                            this.setState({
                                installUser:[list.list[0]]
                            })
                        }
                    })
                }
                this.setState({
                    terminal: [terminal]
                })
                layer.close(index);
            }
        })
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup', 'auditStatusDup', 'location', 'qrcode']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }
				

                let client = JSON.parse(data[0]);
                this.setState( {
                    client: [client]
                })
				
                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    let pei = {
                        username:'contact',
                        phone:'tel'
                    };
                    let fields = ['bankAddress', 'address', 'corporate', 'corporateIdcode', 'corporateValidityStartDate', 'corporateValidityEndDate', 'corporatePhone', 'corporateTel', 'corporateFax', 'lendRate', 'loanRate','outRate','originRate','specialCoast', 'level','expansion','expansionCoast','platform','bankAgent','expansionType','channel',
                        'transactionControl', 'receiveDate','postcode','serialNumber','risk','registerDate','startDate','aliCoastRate','wechatCoastRate','authorized', 'authorizedIdcode', 'authorizedValidityStartDate', 'authorizedValidityEndDate'
                    ];
                    for(let k in fields) {
                        let field = fields[k];
                        // if (data.information.hasOwnProperty(field) && !model[field]) {
                        model[field] = data.information[field];
                        // }
                    }
                    for(let k in pei) {
                        let field = pei[k];
                        // if (! model[k] && data.information[field]) {
                        model[k] = data.information[field];
                        // }
                    }
                    if (data.information.receiveBank) {
                        this.server.getService(BankService).getDetail(data.information.receiveBank, bank => {
                            this.setState({
                                receiveBank:[bank]
                            })
                        })
                    }
                    if (data.information.bank) {
                        this.server.getService(BankService).getDetail(data.information.bank, bank => {
                            this.setState({
                                bank:[bank]
                            })
                        })
                    }
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }

    installUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.installUser}
                show={this.state.installUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'installUid'}
            />
        )
    }

    chooseInstallUser() {
        this.layOpen({
            title: '选择安装人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.installUid = user.id;
                    return {
                        installUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    checkUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.checkUser}
                show={this.state.checkUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'checkUid'}
            />
        )
    }

    receiveBank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.receiveBank.length}
                list={state.receiveBank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'receiveBankId'}
            />
        )
    }

    chooseReceiveBank(form) {
        this.layOpen({
            title:'选择收单银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    receiveBank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择开户银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }
}
