import View from "../../../../../vendor/framework/view";
import { Fragment } from 'react';
import style from './index.module.css'
import detail from './detail.module.css'
import File from "../../../../../vendor/framework/file";

class Detail extends View
{
    file = new File();

    boot(props) {
        this.state =props.state || {
            model:props.model || {}
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.model) {
            this.setModel(nextProps.model);
        }
    }

    fieldsInit() {
        let fields = this.props.fields;
        if (fields instanceof Array) {
            return (
                <div className="layui-fluid" style={{padding: '0 20px'}}>
                    <div className="layui-card layui-form" >
                        {this.fieldsArray(fields)}
                    </div>
                </div>
            )
        }
        return (
            <div className={detail['layui-form']} style={{padding: '0 0 0 0'}}>
                <div className={detail['order-box']}>
                    {this.fieldsObject(fields)}
                </div>
            </div>
        )
    }

    fieldsArray(fields) {
        let context = [];
        for(let k in fields) {
            let list = fields[k],
                showType = typeof list.show,
                show = showType === 'undefined' || (showType === 'function' ? list.show(this.state.model) : list.show);

            if (show) {
                context.push(
                    <Fragment>
                        <div key={k} className={detail['order-box1']}>
                            {list.title ?
                                (
                                    <fieldset className="layui-elem-field layui-field-title" style={{marginTop: '20px',color: '#f00'}}>
                                        <legend>{typeof list.title === 'function' ? list.title(this.state.model) : list.title}</legend>
                                    </fieldset>
                                )
                                :
                                ''}
                            {this.fieldsObject(list.fields)}
                        </div>
                        {k < fields.length ? <hr /> : ''}
                    </Fragment>
                )
            }
        }
        return context;
    }

    fieldsObject(fields) {
        let context = [];
        for(let k in fields) {
            let item = fields[k],
                isObject = typeof item === 'object',
                func = isObject ? (item.type || 'text'): 'text',
                show = isObject ? (typeof item.show === 'function' ? item.show(this.state.model) : (item.show !== undefined ? item.show : true)) : true,
                name =  isObject ? item.name : item,
                val;
            if (show && typeof this[func] === 'function') {
                if (isObject && item.value !== undefined && item.value !== false) {
                    val = typeof item.value === 'function' ? item.value(this.state.model) : item.value;
                } else {
                    if (k.indexOf('.') > -1) {
                        let keys = k.split('.');
                        val = this.state.model[keys[0]];
                        for(let k in keys) {
                            if (Number(k) > 0) {
                                if (val && typeof val === 'object') {
                                    val = val[keys[k]];
                                }
                            }
                        }
                    } else {
                        val = this.state.model[k];
                    }
                }
                context.push(this[func](
                    name,
                    val,
                    isObject ? item : {
                        block:false
                    }
                ));
            }
        }
        return context;
    }

    text(name, value, item) {
        return (
            <div className={item.block ? detail['order-block'] :style['form-item']}>
                {name ? (<label className={item.block ? style['layui-form-label'] : style['layui-form-label']}>{name}:</label>) : ''}
                <div className={item.block ? detail["layui-input-block"] : style["layui-input-inline"]}>
                    <div className={detail['order-item']}><span style={item.block ? (item.style || {marginTop:'8px'}) : {}}>{typeof val !== 'object' ? value : ''}</span></div>
                </div>
            </div>
        )
    }

    html(name, value, item) {
        return (
            <div className={item.block ? detail['order-block'] : style['form-item']}>
                {item.name ? (<label className={item.block ? detail['layui-form-label'] : style['layui-form-label']}>{name}:</label>) : ''}
                <div className={item.block ? detail["layui-input-block"] : style["layui-input-inline"]}>
                    <div className={detail['order-item']} dangerouslySetInnerHTML={{__html:value}}></div>
                </div>
            </div>
        )
    }

    image(name, value, item) {
        return (
            <div className={item.block ? style['order-block'] : style['form-item']}>
                <label className={style['layui-form-label']}>{name}:</label>
                <div className="layui-input-inline">
                    <div className={style['order-item']}>
                        <span>
                            {value ? (<div className={"tsimgbox"}><img slide={item.slide !== undefined ? item.slide : false} className={"psimg"} src={this.file.make(value)}/></div>) : item.default || ''}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    slide(name, value, item) {
        let slide = typeof value === 'string' ? value.split(item.split || ',') : value;

        return (
            <div className={item.block ? style['order-block'] : style['form-item']}>
                <label className={style['layui-form-label']}>{name}:</label>
                <div className="layui-input-inline">
                    <div className={style['order-item']}>
                    <span>
                        {
                            (slide || []).map((val, index) => {
                                return val ? (
                                    <div key={index} className={"tsimgbox"}>
                                        <img slide={item.slide !== undefined ? item.slide : undefined} className={"psimg"} src={this.file.make(val)}/>
                                    </div>
                                ) : ''
                            })
                        }
                    </span>
                    </div>
                </div>
            </div>
        )
    }

    itemComponent(name, value, item) {
        return (
            <div className={item.block ? detail['order-block'] :style['form-item']}>
                {name ? (<label className={item.block ? style['layui-form-label'] : style['layui-form-label']}>{name}:</label>) : ''}
                <div className={item.block ? detail["layui-input-block"] : style["layui-input-inline"]}>
                    <div className={detail['order-item']}>
                        {typeof item.component === 'function' ? item.component(this.state) : item.component}
                    </div>
                </div>
            </div>
        )
    }

    component(name, value, item) {
        return typeof item.component === 'function' ? item.component(this.state.model) : item.component;
    }

    render() {
        return this.fieldsInit();
    }
}
export default Detail;
