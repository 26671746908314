import Route from '../vendor/framework/route';
import Login from "../app/admin/views/login";
import Index from "../app/admin/views/index";
import UserList from "../app/admin/views/user/list";
import AdminList from "../app/admin/views/administraitor/admin/list";
import AdminAdd from "../app/admin/views/administraitor/admin/add";
import AdminEdit from "../app/admin/views/administraitor/admin/edit";
import AdminInformationDetail from "../app/admin/views/administraitor/admin/information/list";
import AdminInformationEdit from "../app/admin/views/administraitor/admin/information/edit";
import RoleList from "../app/admin/views/administraitor/role/list";
import RoleAdd from "../app/admin/views/administraitor/role/add";
import RoleEdit from "../app/admin/views/administraitor/role/edit";
import RuleList from "../app/admin/views/administraitor/rule/list";
import RuleAdd from "../app/admin/views/administraitor/rule/add";
import AuthMiddleware from "../app/admin/middlewares/auth";
import GuestMiddleware from "../app/admin/middlewares/guest";
import RuleIcon from "../app/admin/views/administraitor/rule/icon";
import RuleEdit from "../app/admin/views/administraitor/rule/edit";
import RoleChooseList from "../app/admin/views/administraitor/role/choose";
import Error from "../app/admin/views/index/error";
import BannerList from "../app/admin/views/running/banner/list";
import BannerAdd from "../app/admin/views/running/banner/add";
import BannerEdit from "../app/admin/views/running/banner/edit";
import UserAdd from "../app/admin/views/user/add";
import UserEdit from "../app/admin/views/user/edit";
import CompanyUserList from "../app/admin/views/company/user/list";
import ActivityList from "../app/admin/views/running/activity/list";
import ActivityAdd from "../app/admin/views/running/activity/add";
import ActivityEdit from "../app/admin/views/running/activity/edit";
import UserTypeList from "../app/admin/views/running/userType/list";
import UserTypeAdd from "../app/admin/views/running/userType/add";
import UserTypeEdit from "../app/admin/views/running/userType/edit";
import UserAttrAdd from "../app/admin/views/running/userType/attr/add";
import UserAttrEdit from "../app/admin/views/running/userType/attr/edit";
import UserAttrList from "../app/admin/views/running/userType/attr/list";
import Maper from "../app/admin/views/map";
import PosList from "../app/admin/views/device/pos/list";
import PosImport from "../app/admin/views/device/pos/import";
import AgreementList from "../app/admin/views/running/agreement/list";
import AgreementAdd from "../app/admin/views/running/agreement/add";
import AgreementEdit from "../app/admin/views/running/agreement/edit";
import ReceiptCodeList from "../app/admin/views/running/receiptCode/list";
import ReceiptCodeAdd from "../app/admin/views/running/receiptCode/add";
import ReceiptCodeEdit from "../app/admin/views/running/receiptCode/edit";
import HelpList from "../app/admin/views/running/help/list";
import HelpAdd from "../app/admin/views/running/help/add";
import HelpEdit from "../app/admin/views/running/help/edit";
import OrderInstallList from "../app/admin/views/order/install/list";
import OrderInstallAdd from "../app/admin/views/order/install/add";
import UserChooseList from "../app/admin/views/user/choose";
import PosChooseList from "../app/admin/views/device/pos/choose";
import KeybordList from "../app/admin/views/device/keybord/list";
import KeybordImport from "../app/admin/views/device/keybord/import";
import KeybordChooseList from "../app/admin/views/device/keybord/choose";
import DeviceChoose from "../app/admin/views/device/choose";
import SimList from "../app/admin/views/device/sim/list";
import SimImport from "../app/admin/views/device/sim/import";
import SimChooseList from "../app/admin/views/device/sim/choose";
import CompanyUserChooseList from "../app/admin/views/company/user/choose";
import OrderMaintainingList from "../app/admin/views/order/maintaining/list";
import OrderRepealList from "../app/admin/views/order/repeal/list";
import OrderPatrolList from "../app/admin/views/order/patrol/list";
import ReceiptCodeTypeList from "../app/admin/views/running/receiptCode/type/list";
import ReceiptCodeTypeAdd from "../app/admin/views/running/receiptCode/type/add";
import ReceiptCodeTypeEdit from "../app/admin/views/running/receiptCode/type/edit";
import VersionPackageList from "../app/admin/views/running/versionPackage/list";
import VersionPackageAdd from "../app/admin/views/running/versionPackage/add";
import VersionPackageEdit from "../app/admin/views/running/versionPackage/edit";
import BankList from "../app/admin/views/running/bank/list";
import BankAdd from "../app/admin/views/running/bank/add";
import BankEdit from "../app/admin/views/running/bank/edit";
import BankChooseList from "../app/admin/views/running/bank/choose";
import UserTypeChooseList from "../app/admin/views/running/userType/choose";
import UserAttrChooseList from "../app/admin/views/running/userType/attr/choose";
import CompanyUserAdd from "../app/admin/views/company/user/add";
import CompanyUserEdit from "../app/admin/views/company/user/edit";
import OrderInstallEdit from "../app/admin/views/order/install/edit";
import Console from "../app/admin/views/console";
import OrderInstallDetail from "../app/admin/views/order/install/detail";
import UserDetail from "../app/admin/views/user/detail";
import CompanyUserDetail from "../app/admin/views/company/user/detail";
import OrderMaintainingAdd from "../app/admin/views/order/maintaining/add";
import OrderMaintainingEdit from "../app/admin/views/order/maintaining/edit";
import OrderMaintainingDetail from "../app/admin/views/order/maintaining/detail";
import OrderPatrolDetail from "../app/admin/views/order/patrol/detail";
import OrderRepealDetail from "../app/admin/views/order/repeal/detail";
import OrderRepealEdit from "../app/admin/views/order/repeal/edit";
import AnnouncementList from "../app/admin/views/running/announcement/list";
import AnnouncementAdd from "../app/admin/views/running/announcement/add";
import AnnouncementEdit from "../app/admin/views/running/announcement/edit";
import UserImport from "../app/admin/views/user/import";
import UserDeviceImport from "../app/admin/views/user/device/import";
import UserDeviceList from "../app/admin/views/user/device/list";
import UserDeviceChooseList from "../app/admin/views/user/device/choose";
import UserDeviceAdd from "../app/admin/views/user/device/add";
import UserDeviceEdit from "../app/admin/views/user/device/edit";
import UserDeviceDetail from "../app/admin/views/user/device/detail";
import OrderInstallImport from "../app/admin/views/order/install/import";
import OrderInstallApplyList from "../app/admin/views/order/install/apply/list";
import OrderInstallInstallUserImport from "../app/admin/views/order/install/import/install";
import OrderInstallApplyDetail from "../app/admin/views/order/install/apply/detail";
import OrderUserInstallList from "../app/admin/views/order/install/user";
import OrderPatrolImport from "../app/admin/views/order/patrol/import";
import OrderPatrolImportAppoint from "../app/admin/views/order/patrol/import/appoint";
import OrderPatrolAdd from "../app/admin/views/order/patrol/add";
import OrderPatrolEdit from "../app/admin/views/order/patrol/edit";
import OrderMaintainingImportAppoint from "../app/admin/views/order/maintaining/import/appoint";
import OrderMaintainingImport from "../app/admin/views/order/maintaining/import";
import OrderRepealAdd from "../app/admin/views/order/repeal/add";
import OrderRepealImport from "../app/admin/views/order/repeal/import";
import OrderRepealImportAppoint from "../app/admin/views/order/repeal/import/appoint";
import OrderPaymentQrcodeList from "../app/admin/views/order/payment-qrcode/list";
import OrderPaymentQrcodeDetail from "../app/admin/views/order/payment-qrcode/detail";
import DeviceLogList from "../app/admin/views/device/log/list";
import DeviceTypeList from "../app/admin/views/device/type/list";
import DeviceList from "../app/admin/views/device/device/list";
import DeviceImport from "../app/admin/views/device/device/import";

var namespace = '/admin';

var paths = [
    {
        path: '/login',
        component: Login,
        middleware: [new GuestMiddleware()]
    },
    {
        path: '/',
        middleware: [new AuthMiddleware()],
        children: [
            {
                path: '/index',
                component: Index
            },
            {
                path:'/error',
                component: Error
            },
            {
                path:'/',
                middleware:[],
                children:[
                    {
                        path:'/map',
                        component: Maper
                    },
                    {
                        path:'/console',
                        component: Console
                    },
                    {
                        path:'/device',
                        children: [
							{
							    path:'/device/list',
							    component: DeviceList
							},
							
                            {
                                path:'/device/import',
                                component: DeviceImport
                            },							
							
                            {
                                path:'/pos/list',
                                component: PosList
                            },
                            {
                                path:'/pos/import',
                                component: PosImport
                            },
                            {
                                path:'/pos/choose/list',
                                component: PosChooseList
                            },
                            {
                                path:'/keyboard/list',
                                component: KeybordList
                            },
                            {
                                path:'/keyboard/import',
                                component: KeybordImport
                            },
                            {
                                path:'/keyboard/choose/list',
                                component: KeybordChooseList
                            },
                            {
                                path:'/sim/list',
                                component: SimList
                            },
                            {
                                path:'/sim/import',
                                component: SimImport
                            },
                            {
                                path:'/sim/choose/list',
                                component: SimChooseList
                            },
							{
							    path:'/log/:id',
							    component: DeviceLogList
							},
							{
							    path:'/type/list',
							    component: DeviceTypeList
							},
							{
							    path:'/type/add',
							    component: DeviceTypeList
							},
                        ]
                    },
                    {
                        path:'/device/choose',
                        component: DeviceChoose
                    },
                    {
                       path:'/user',
                       children: [
                           {
                               path: '/list',
                               component: UserList
                           },
                           {
                               path:'/add',
                               component: UserAdd
                           },
                           {
                               path:'/edit/:id',
                               component: UserEdit
                           },
                           {
                               path:'/choose/list',
                               component: UserChooseList
                           },
                           {
                               path:'/detail/:id',
                               component: UserDetail
                           },
                           {
                               path:'/import',
                               component: UserImport
                           },
                           {
                               path:'/device/list',
                               component: UserDeviceList
                           },
                           {
                               path:'/device/choose/list',
                               component: UserDeviceChooseList
                           },
                           {
                               path:'/device/add',
                               component: UserDeviceAdd
                           },
                           {
                               path:'/device/edit/:id',
                               component: UserDeviceEdit
                           },
                           {
                               path:'/device/detail/:id',
                               component: UserDeviceDetail
                           },
                           {
                               path:'/device/import',
                               component: UserDeviceImport
                           }
                       ]
                    },
                    {
                        path:'/company/user/list',
                        component: CompanyUserList
                    },
                    {
                        path:'/company/user/add',
                        component: CompanyUserAdd
                    },
                    {
                        path:'/company/user/edit/:id',
                        component: CompanyUserEdit
                    },
                    {
                        path:'/company/user/detail/:id',
                        component: CompanyUserDetail
                    },
                    {
                        path:'/company/user/choose/list',
                        component: CompanyUserChooseList
                    },
                    {
                        path: '/admin/list',
                        component: AdminList
                    },
                    {
                        path: '/admin/add',
                        component: AdminAdd
                    },
                    {
                        path: '/admin/edit/:id',
                        component: AdminEdit
                    },
                    {
                        path: '/admin/information',
                        component: AdminInformationDetail
                    },
                    {
                        path: '/admin/password/edit',
                        component: AdminInformationEdit
                    },
                    {
                        path: '/role/list',
                        component: RoleList
                    },
                    {
                        path: '/role/add',
                        component: RoleAdd
                    },
                    {
                        path: '/role/edit/:id',
                        component: RoleEdit
                    },
                    {
                        path:'/role/choose/list',
                        component: RoleChooseList
                    },
                    {
                        path: '/rule/list',
                        component: RuleList
                    },
                    {
                        path:'/rule/icon',
                        component: RuleIcon
                    },
                    {
                        path: '/rule/add/:pid?',
                        component: RuleAdd
                    },
                    {
                        path: '/rule/edit/:id',
                        component: RuleEdit
                    },
                    {
                        path:'/order/install/detail/:id',
                        component: OrderInstallDetail
                    },
                    {
                        path:'/order/install/list',
                        component: OrderInstallList
                    },
                    {
                        path:'/order/install/add',
                        component: OrderInstallAdd
                    },
                    {
                       path:'/order/install/edit/:id',
                       component: OrderInstallEdit
                    },
                    {
                        path:'/order/install/import',
                        component: OrderInstallImport
                    },
                    {
                        path:'/order/install/apply/list',
                        component: OrderInstallApplyList
                    },
                    {
                        path:'/order/install/install/import',
                        component: OrderInstallInstallUserImport
                    },
                    {
                        path:'/order/install/apply/detail/:id',
                        component: OrderInstallApplyDetail
                    },
                    {
                        path:'/order/user/install/list',
                        component: OrderUserInstallList
                    },
                    {
                        path:'/order/maintaining/list',
                        component: OrderMaintainingList
                    },
                    {
                        path:'/order/maintaining/add',
                        component: OrderMaintainingAdd
                    },
                    {
                        path:'/order/maintaining/edit/:id',
                        component: OrderMaintainingEdit
                    },
                    {
                        path:'/order/maintaining/detail/:id',
                        component: OrderMaintainingDetail
                    },
                    {
                        path:'/order/repeal/add',
                        component: OrderRepealAdd
                    },
                    {
                        path:'/order/repeal/list',
                        component: OrderRepealList
                    },
                    {
                        path:'/order/repeal/detail/:id',
                        component: OrderRepealDetail
                    },
                    {
                        path:'/order/repeal/edit/:id',
                        component: OrderRepealEdit
                    },
                    {
                        path:'/order/repeal/import',
                        component: OrderRepealImport
                    },
                    {
                        path: '/order/repeal/import/appoint',
                        component: OrderRepealImportAppoint
                    },
                    {
                        path:'/order/patrol/list',
                        component: OrderPatrolList
                    },
                    {
                        path:'/order/patrol/detail/:id',
                        component: OrderPatrolDetail
                    },
                    {
                        path:'/order/patrol/import',
                        component: OrderPatrolImport
                    },
                    {
                        path:'/order/maintaining/import/appoint',
                        component: OrderMaintainingImportAppoint
                    },
                    {
                        path:'/order/maintaining/import',
                        component: OrderMaintainingImport
                    },
                    {
                        path:'/order/patrol/import/appoint',
                        component: OrderPatrolImportAppoint
                    },
                    {
                        path:'/order/patrol/add',
                        component: OrderPatrolAdd
                    },
                    {
                        path:'/order/patrol/edit/:id',
                        component: OrderPatrolEdit
                    },
                    {
                        path:'/order/payment-qrcode/list',
                        component: OrderPaymentQrcodeList
                    },
                    {
                        path:'/order/payment-qrcode/detail/:id',
                        component: OrderPaymentQrcodeDetail
                    },
                    {
                       path:'/running',
                       children: [
                           {
                               path:'/banner/list',
                               component: BannerList
                           },
                           {
                               path:'/banner/add',
                               component: BannerAdd
                           },
                           {
                               path:'/banner/edit/:id',
                               component: BannerEdit
                           },
                           {
                               path:'/activity/list',
                               component: ActivityList
                           },
                           {
                               path:'/activity/add',
                               component: ActivityAdd
                           },
                           {
                               path:'/activity/edit/:id',
                               component: ActivityEdit
                           },
                           {
                               path:'/userType/list',
                               component: UserTypeList
                           },
                           {
                              path:'/userType/choose/list',
                              component: UserTypeChooseList
                           },
                           {
                               path:'/userType/add',
                               component: UserTypeAdd
                           },
                           {
                               path:'/userType/edit/:id',
                               component: UserTypeEdit
                           },
                           {
                               path:'/userAttr/list',
                               component: UserAttrList
                           },
                           {
                               path:'/userAttr/choose/list',
                               component: UserAttrChooseList
                           },
                           {
                               path:'/userAttr/add',
                               component: UserAttrAdd
                           },
                           {
                               path:'/userAttr/edit/:id',
                               component: UserAttrEdit
                           },
                           {
                               path:'/agreement/list',
                               component: AgreementList
                           },
                           {
                               path:'/agreement/add',
                               component: AgreementAdd
                           },
                           {
                               path:'/agreement/edit/:id',
                               component: AgreementEdit
                           },
                           {
                               path:'/receipt-code/list',
                               component: ReceiptCodeList
                           },
                           {
                               path:'/receipt-code/add',
                               component: ReceiptCodeAdd
                           },
                           {
                               path:'/receipt-code/edit/:id',
                               component: ReceiptCodeEdit
                           },
                           {
                              path:'/receipt-code/type/list',
                              component: ReceiptCodeTypeList
                           },
                           {
                               path:'/receipt-code/type/add',
                               component: ReceiptCodeTypeAdd
                           },
                           {
                               path:'/receipt-code/type/edit/:id',
                               component: ReceiptCodeTypeEdit
                           },
                           {
                               path:'/help/list',
                               component:HelpList
                           },
                           {
                               path:'/help/add',
                               component: HelpAdd
                           },
                           {
                               path:'/help/edit/:id',
                               component: HelpEdit
                           },
                           {
                               path:'/version-package/list',
                               component: VersionPackageList
                           },
                           {
                               path:'/version-package/add',
                               component: VersionPackageAdd
                           },
                           {
                               path:'/version-package/edit/:id',
                               component: VersionPackageEdit
                           },
                           {
                               path:'/bank/list',
                               component: BankList
                           },
                           {
                               path:'/bank/add',
                               component: BankAdd
                           },
                           {
                               path:'/bank/edit/:id',
                               component: BankEdit
                           },
                           {
                               path:'/bank/choose/list',
                               component: BankChooseList
                           },
                           {
                               path:'/announcement/list',
                               component: AnnouncementList
                           },
                           {
                               path:'/announcement/add',
                               component: AnnouncementAdd
                           },
                           {
                               path:'/announcement/edit/:id',
                               component: AnnouncementEdit
                           }
                       ]
                    },
                    {
                        path:'/system',
                        children:[

                        ]
                    }
                ]
            },
        ]
    },

];

export default class Admin extends Route {
    constructor(props) {
        super(props);
        this.namespace = namespace;
        this.paths = paths;
    }
}
