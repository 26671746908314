import Base from "../../../base";
import OrderPatrolService from "../../../../services/order/patrol";
import Detail from "../../../layout/detail/detail";

export default class OrderPatrolDetail extends Base
{
    boot() {
        this.state = {
            model:{

            },
        }
    }

    service() {
        return OrderPatrolService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={[
                    {
                        title:'工单信息',
                        fields:{
                            orderSn:'工单号',
                            username:'联系人',
                            phone:'电话',
                            address:'地址',
                            remark:{name:'备注(问题描述)', block: true},
                           // visitedAt:'上门时间',
                            servedAt:'完成时间',
                            createdAt:'添加时间',
							notes:{name:'员工备注',block: true},
                            statusDup:'状态',
							
                            auditReason:{name:'审核理由', show: model => model.auditReason }
                        }
                    },
                    {
                        title:'商户信息',
                        fields:{
                            'client.company.name':'所属公司',
                            'client.name':'商户名称',
                            detail:{name:'详情', type :'itemComponent', component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }
                            },
                        }
                    },
                    {
                       title:'终端信息',
                       fields:{
                           terminalCode:'终端号',
                           terminal:{
                               name:"详情",
                               type:'itemComponent',
                               component: () => {
                                   return (
                                       <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showTerminal()}>
                                           <i className="layui-icon layui-icon-list"></i>
                                           查看
                                       </button>
                                   )
                               }
                           }
                       }
                    },
                    {
                        title:'巡检人信息',
                        fields:{
                            'checkUser.realname':'负责人',
                            installBtn:{
                                name:'巡检人信息',
                                show: model => {
                                    return model.checkUid;
                                },
                                type:'itemComponent',
                                component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showCompanyUser()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }
                            }
                        }
                    },
                    {
                        title:'巡检信息',
                        show:model => {
                            return model.status !== 0;
                        },
                        fields:{
                            facadeImg:{name:'门面照', type:'slide', slide: 'slide'},
                            innerImg:{name:'内部照', type:'slide', slide: 'slide'},
                            deskImg:{name:'前台照', type:'slide', slide: 'slide'},
                            physicalImg:{name:'机身照', type:'slide', slide: 'slide'},
                            salesImg:{name:'签购单', type:'slide', slide: 'slide'},
							signImg:{name:'签名照', type:'slide', slide: 'slide'},
                            result:{name:'结果', value: model => { return this.server.result[model.result] }, block: true},
                            reason:{name:'备注原因', block: true},
							posDeviceNum:{name:'机身号', block: true},
                            isGeneralSign:{
                                name:'是否已签署',
                                value: model => model.generalSigned ? '是' : '否'
                            }
                        }
                    }
                ]}
            />
        )
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['90%', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    showCompanyUser() {
        let checkUser = this.state.model.checkUser;
        this.layOpen({
            title:'查看巡检人' + checkUser.realname,
            content:'/admin/company/user/detail/'+ checkUser.id,
            area:['90%', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }

    showTerminal() {
        this.layOpen({
            title:'详情',
            content:'/admin/user/device/detail/' +this.state.model.device.id,
            area: ['90%', '98%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}
