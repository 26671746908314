import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

export default class CompanyUserService extends BaseService
{
    requestUrls = {
        url:'/company/user/list',
        save:'/company/user/save',
        detail:'/company/user/detail',
        delete:'/company/user/delete',
        locationList:'/company/user/location/list'
    }

    file = new File();

    locateMode = ['手动', '自动'];

    status = ['禁用', '启用'];

    appends = ['statusDup', 'locateModeDup'];

    searchList(keywords, suc) {
        this.post(
            this.requestUrls.url,
            {keywords:keywords},
            suc
        )
    }

    save(model, suc, err) {
        var validator = new Validator(),
            rules = [
                ['username', 'required', '请填写员工账号'],
                ['password', (!model.id ? 'required|' : '') + 'min:6|max:20', (!model.id ? '请填写密码|' : '') + '密码至少6位|密码最多20位'],
                ['realname', 'required', '请填写员工姓名'],
                ['phone', 'required|mobile', '请填写手机号码|手机号码格式不正确'],
            ];

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        if (model.portrait) {
            model.portrait = this.file.filter(model.portrait);
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }

    getLocationList(data, suc) {
        this.post(
            this.requestUrls.locationList,
            data,
            suc
        )
    }

    getPortraitAttribute() {
        return this.file.make(this.attributes['portrait']);
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getLocateModeDupAttribute() {
        return this.locateMode[this.attributes['locateMode']];
    }
}
