import Base from "../../../base";
import OrderMaintainingService from "../../../../services/order/maintaining";
import UserService from "../../../../services/user";
import Detail from "../../../layout/detail/detail";

export default class OrderMaintainingDetail extends Base
{
    boot() {
        this.state = {
            model:{
                username:'',
                phone:'',
                address:'',
                orderType:0,
                status:0,
                deviceId:0,
                device:{

                }
            },
        }
    }

    service() {
        return OrderMaintainingService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
            if (data.deviceId) {
                this.server.getService(UserService).getDeviceDetail(data.deviceId, device => {
                    this.setModel({
                        device:device
                    })
                })
            }
        })
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={[
                    {
                        title:'工单信息',
                        fields:{
                            orderSn:'工单号',
                            orderTypeDup:'工单类型',
                            deviceTypeDup:{name:'设备类型', show: model => model.orderType === 0 },
                            username:'联系人',
                            phone:'电话',
                            address:'地址',
                            remark:{name:'备注(问题描述)', block: true},
                            statusDup:'状态',
                            visitedAt:'上门时间',
                            servedAt:'完成时间',
                            resultDup:{name:'结果', block: true, value: model => { return model.status ? model.resultDup : '无'; }},
                            resultImg:{name:'结果图片', block: true, type:'image', show: model => { return model.resultImg; }},
                            createdAt:'添加时间',
							notes:{name:'员工备注',block: true},
                        }
                    },
                    {
                        title:'终端信息',
                        fields:{
                            terminalCode:'终端号',
                            detail:{name:'详情', type :'itemComponent', component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showTerminal()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }
                            },
                        }
                    },
                    {
                        title:'故障信息',
                        show: model => {
                            return model.orderType === 1;
                        },
                        fields:{
                            'malfunction.facadeImg':{name:'门面照', type:'slide', slide: 'slide'},
                            'malfunction.receipt':{name:'凭证', type:'slide', slide: 'slide'},
                            'malfunction.problemImg':{name:'故障画面', type:'slide', slide: 'slide'},
                            'malfunction.innerImg':{name:'内部照', type:'slide', slide: 'slide'},
                            'malfunction.desc':{name:'描述', block: true},
                        }
                    },
                    {
                        title:'商户信息',
                        fields:{
                            'client.company.name':'所属公司',
                            'client.name':'商户名称',
                            detail:{name:'详情', type :'itemComponent', component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }
                            },
                        }
                    },
                    {
                        title:'负责人信息',
                        fields:{
                            'companyUser.realname':'负责人',
                            installBtn:{
                                name:'负责人信息',
                                show: model => {
                                    return model.cuid;
                                },
                                type:'itemComponent',
                                component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showCompanyUser()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }
                            }
                        }
                    },
                    {
                        title:'维护信息',
                        show:model => {
                            return model.status !== 0;
                        },
                        fields:{
                            facadeImg:{name:'门面照', type:'slide', slide: 'slide'},
                            innerImg:{name:'内部照', type:'slide', slide: 'slide'},
                            deskImg:{name:'前台照', type:'slide', slide: 'slide'},
                            physicalImg:{name:'机身照', type:'slide', slide: 'slide'},
                            salesImg:{name:'签购单', type:'slide', slide: 'slide'},
							signImg:{name:'签名照', type:'slide', slide: 'slide'},
                            result:{name:'结果', value: model => { return this.server.result[model.result] }, block: true},
                            reason:{name:'备注原因', block: true},
							posDeviceNum:{name:'机身号', block: true}
							
                        }
                    }
                ]}
            />
        );
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['900px', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    showCompanyUser() {
        let companyUser = this.state.model.companyUser;
        this.layOpen({
            title:'查看负责人' + companyUser.realname,
            content:'/admin/company/user/detail/'+ companyUser.id,
            area:['800px', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }

    showTerminal() {
        this.layOpen({
            title:'详情',
            content:'/admin/user/device/detail/'+this.state.model.deviceId,
            area: ['90%', '98%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}
