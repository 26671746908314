import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import DeviceTypeService from "../../../../services/device/type";

export default class DeviceTypeListLayout extends View
{
    boot() {
        this.state = {
            orderBy:['id', 'desc'],
             
        }
    }

    service() {
        return  DeviceTypeService;
    }

    render() {
        return (
            <Table
                props={this.props}
                service={DeviceTypeService}
                stateInit={this.state}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    name:'设备类型',
                    createdAt:'时间',
                }}
				 
                 
            />
        );
    }

    componentDidMount() {
        /* global layer */
    }

     
 
	
	 
}
