import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import DeviceService from "../../../../services/device/device";
import * as XLSX from 'xlsx';
import $ from 'jquery';
import Excel from "../../../../components/excel";
import CompanyUserListLayout from "../../../company/user/layout";

export default class DeviceListLayout extends View
{
    boot() {
        let status = this.app.getUrlParams('status');
        this.state = {
            orderBy:['id', 'desc'],
			companyuser:[],
			typeName:'选择类型',
            search:{
                status: status !== undefined ? status : '',
            }
        }
    }
	

    componentDidMount() {
        /* global layer */
    }
    service() {
        return DeviceService;
    }

    render() {
        return (
            <Table
                props={this.props}
                service={DeviceService}
                stateInit={this.state}
				canCheck={true}
				checkMany={true}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                    status:{name:'状态', data: this.server.status, default:'', type:'select'},
					device_status:{name:'设备状态', data: this.server.deviceStatusName, default:'', type:'select'},
					type: {name: '设备类型', type: 'component', component: this.buttonComponent.bind(this)},
					 
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
					typeDup:'类型',
					property:'产权',
                    company:{name:'所属公司', value(model) {
                            return model.company.name;
                        }},
					made:'生产厂商',
                    model:'设备型号',
                    deviceNum:'设备号',
                    statusDup:'库存状态',
					deviceStatusDup:'设备状态',
					comno:'商户号', 
					terminal:'终端号', 
					merchant:'商户名称', 
					recipient:'领取人', 
                    createdAt:'入库时间',
                    proddedAt:'出库时间'
					
                }}
				oprationInit={{
					logdetail: {name: '记录', icon: ['list', 'normal'], click: this.logdetail.bind(this)},
				}}
                btnInit={{
                    import:{name:'excel导入', click: this.import.bind(this)},
                    excelLayout:{name:"excel模板下载", click: this.downloadExcelLayout.bind(this)},
					export:{name:'导出', click: this.export.bind(this)},
					companyuser: {name: '批量领取', click: this.companyuser.bind(this)},
					
                }}
            />
        );
    }





    buttonComponent(table) {
        return (
            <button className={"layui-btn"}
                    onClick={e => this.chooseType(table)}>{table.state.typeName || '所有类型'}</button>
        )
    }
chooseType(table) {
        this.layOpen({
            title: '选择类型',
            content: '/admin/device/type/list',
            form: true,
            area: ['900px', '90%'],
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data,
                    model = data && data.length ? JSON.parse(data[0]) : {id: '', name: ''};
                table.setState({
                    typeName: model.name,
                })
                table.setSearch({
                    'type': model.id
                })
                table.loadData();
                layer.close(index);
            }
        })
    }

  companyuser(table) {
      let ids = [];
      $('input[type=checkbox]').each(function () {
          if ($(this).prop('checked')) {
              let value = $(this).val();
              if (value.indexOf('{') > -1 && value.indexOf('}') > -1) {
                  let val = JSON.parse(value);
                  ids.push(val.id);
              }
          }
      })
      if (!ids.length) {
          return layer.msg('请选择要批量领取的设备!');
      }
  
      this.layOpen({
          title: '选择领取员工',
          content: '/admin/company/user/choose/list',
          form: true,
          yes: (form, index) => {
              form = this.app.htmlArrayToJson(form);
              let data = form.data;
              if (!data || !data.length) {
                  return layer.msg('请选择员工!');
              }
              let user = JSON.parse(data[0]);
              let model = {
                  ids: ids,
                  uid: user.id
              }
              table.server.bindUser(model, () => {
                  layer.msg('已领取!');
                  layer.close(index);
                  table.pageReload();
              }, (msg) => {
                  layer.msg(msg);
              })
          }
      })
  }
  

    import(table) {
        this.layOpen({
            title:'导入',
            content:'/admin/device/device/import',
            form:true,
            yes:(data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.add(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    downloadExcelLayout() {
        let arr = [
           
			 ['序号', '产权', '所属公司', '设备类型', '生产厂商', '设备型号','设备号 (机身号)','库存状态',' 设备状态','商户号','终端号','商户名称','领取人'],
			 ['1', '广州裕富联信息', '广州裕富联信息', '键盘', '生产厂商111', 'K320','k32021k0716','已出库',' 未安装','100001212','334343','广州商户','李工'],
        ]
        // 将数组转化为标签页
        let ws = XLSX.utils.aoa_to_sheet(arr);
        // 创建工作薄
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws);
        XLSX.writeFile(wb,'设备机导入模板.xlsx');
    }
	
	export(table, type = 0) {
	    let ids = this.getCheckIds();
	    if (! ids.length) {
	        return layer.msg('请选择数据!');
	    }
	    this.server.export({ids:ids}, res => {
	        if (type) {
	            let url = (new File()).make(res.path);
	            window.open(url);
	            return;
	        }
	        let list = [];
	        res.forEach(item => {
	            if (ids.indexOf(item.id) > -1) {
	                list.push(item);
	            }
	        })
	        let titles = this.server.getExcelTitles();
	        let keys = Object.keys(this.server.exportTitle),
	            data = [titles];
	        list.forEach(item => {
	            let arr = [],
				company = item.company  || {},
	                row = {
	                    id:item.id,
						property:item.property,
	                    company:company.name,
						typeDup:item.typeDup,
						made:item.made,
	                    model:item.model,
	                    deviceNum:item.deviceNum,
	                    statusDup:item.statusDup,
						deviceStatusDup:item.deviceStatusDup,
						comno:item.comno,
						terminal:item.terminal,
						merchant:item.merchant,
						recipient:item.recipient,
	                    createdAt:item.createdAt,
	                    logDup:item.logDup,
						
						 
	                }
	                keys.forEach(field => {
	                    arr.push(row[field] || '');
	                })
	            data.push(arr);
	        })
			
	        let excel = new Excel();
	        excel.export('设备列表', data, (workbook, table) => {
	            workbook.sheet(0).row(1).height(50);
	            workbook.sheets().forEach((sheet) => {
	                // 表头加粗及背景色
	                sheet.range('A1:BM1').style({
	                    wrapText: true,
	                    fill: "00bdf0",
	                    bold: true,
	                    horizontalAlignment: "center",
	                    verticalAlignment: "center",
	                    fontSize: 12,
	                    border: {
	                        style: 'thin',
	                        color: "000000",
	                        direction: "both"
	                    }
	                });
	
	                for (let k in table[0]) {
	                    sheet.column(k).width(25);
	                }
	            })
	        })
	    })
	}
	
	
	
	
		
	logdetail(model) {
	    this.layOpen({
	        title: '日志记录【' + model.deviceNum+'】',
	        content: '/admin/device/log/' + model.id,
	        area: ['90%', '90%'],
	        btn: ['确定'],
	        yes: (index) => {
	            layer.close(index);
	        }
	    })
	}
	
	
	
	
	chooseCompanyuser(form) {
		
		return true;
		
		 
		
	}
	
	
	 
	
	
	
	
	
	
}
