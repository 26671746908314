import Base from "../../../base";
import SimListLayout from "../layout";

export default class SimChooseList extends Base
{
    render() {
        return (
            <SimListLayout
                canCheck={true}
                btnShow={false}
                oprationShow={false}
            />
        );
    }
}
