import View from "../../../../vendor/framework/view";
import Image from "./image";
import style from "./slides.module.css"
import Editor from "./editor";
import Video from "./video";
import md5 from "js-md5";
import Slides from "./slides";
import File from "./file";

class Form extends View {
    constructor(props) {
        super(props);
        this.state = props.state || {
            model: props.model || {}
        }
        this.defaultType = 'text';
    }

    componentDidMount() {
        /* global layui */
        let modules = ['form'],
            fieldsInit = this.props.fields;
        for(var k in fieldsInit) {
            var item = fieldsInit[k];
            if (typeof item === 'object') {
                getDateModule(item);
            }
        }
        function getDateModule(item) {
            if (item.hasOwnProperty('fields') && typeof item.fields === 'object') {
                for(let k in item.fields) {
                    let it = item.fields[k];
                    if (typeof it === 'object' && typeof it.fields === 'object') {
                        getDateModule(it);
                    } else if (it.type === 'date' && modules.indexOf('laydate') === -1) {
                        modules.push('laydate');
                    }
                }
            } else if (item.type == 'date') {
                modules.push('laydate');
            }
        }

        layui.use(modules, () => {
            let form = layui.form,
                _this = this,
                laydate,
                types = ['radio', 'select'];

            if (this.props.formVerify) {
                form.verify(this.props.formVerify);
            }

            if (modules.indexOf('laydate') > -1) {
                laydate = layui.laydate;
            }

            let dates = [];
            for(let k in this.props.fields) {
                let item = this.props.fields[k];
                if (Boolean(item) && typeof item === 'object') {
                    fieldsRender(k, item);
                }
            }

            function fieldsRender(k, item, father) {
                if (Boolean(item) && typeof item === 'object' && Boolean(item.fields) && typeof item.fields === 'object' && !(item.fields instanceof Array)) {
                    for(let j in item.fields) {
                        let it = item.fields[j];
                        if (typeof it === 'object' && typeof it.fields === 'object') {
                            fieldsRender(j, it, item);
                        } else {
                            layRender(j, it, item);
                        }
                    }
                } else {
                    layRender(k, item);
                }
            }

            function layRender(k, item, father) {
                if (types.indexOf(item.type) > -1) {
                    form.on(item.type+'(field'+k+')', (data) => {
                        _this.setModel(k, keep(data.value), () => {
                            form.render(item.type);
                            item.change && item.change(_this.state);

                        });
                    })
                } else if (item.type === 'area') {
                    let fields = item.fields;
                    for(let k in fields) {
                        let field = fields[k],
                            index = Number(k);
                        form.on('select(area'+field+')', (data) => {
                            let value = keep(data.value),
                                model = _this.state.model,
                                name = item.value instanceof Array ? item.value[0] : item.value;
                            model[field] = value;
                            if (index === 0) {
                                var cities = [],
                                    areas = [];

                                if (fields[1]) {
                                    model[fields[1]] = '';
                                }
                                if (fields[2]) {
                                    model[fields[2]] = '';
                                }

                                for(var k of item.data) {
                                    if(k[name] === value) {
                                        cities = k.cities;
                                    }
                                }
                                _this.setState({
                                    model:model,
                                    cities:cities,
                                    areas:areas
                                })
                            }
                            if (index === 1) {
                                areas = [];
                                if (fields[2]) {
                                    model[fields[2]] = '';
                                }

                                for(var k of item.data) {
                                    if (k[name] === _this.state.model[fields[0]]) {
                                        for(var j of k.cities) {
                                            if (j[name] === value) {
                                                areas = j.areas;
                                            }
                                        }
                                    }
                                }

                                _this.setState({
                                    model:model,
                                    areas:areas
                                })
                            }
                            if (index === 2) {
                                _this.setState({
                                    model:model
                                })
                            }
                            form.render();
                        })
                    }
                }

                if (item.type === 'date') {
                    dates.push({name:k,item:item, father:father});
                }
            }

            if (dates.length) {
                setTimeout(() => {
                    for(let k of dates) {
                        laydate.render({
                            elem: '#date'+k.name
                            ,type: k.item.format || 'date',
                            trigger:'click'
                            ,done: function(value) {
                                let model = _this.state.model;
                                if (k.father && typeof k.father === 'object' && k.father.key) {
                                    model[k.father.key][k.name] = value;
                                } else {
                                    model[k.name] = value;
                                }
                                _this.setModel(model);
                            }
                        })
                    }
                }, 1000)
            }

            function isNum(v) {
                return typeof Number(v)  === 'number' && !isNaN(v);
            }

            function keep(v) {
                return isNum(v) ? Number(v) : v;
            }
        })
    }

    componentWillReceiveProps(props) {
        let obj = {};
        if (props.model) {
            obj.model = props.model;
        }
        if (props.state) {
            obj = props.state;
        }

        if (JSON.stringify(obj) !== '{}') {
            this.setState(obj, () => {
                layui.form && layui.form.render();
            })
        }
    }

    tip(tip) {
        if (tip) {
            return (
                <div className="layui-input-inline">
                    <span className={style.exp} style={{paddingTop:'8px'}}>* {tip}</span>
                </div>
            )
        }
    }

    hidden(item, key, val, inputName) {
        let name = this.fitInputName(inputName);
        return (
            <div className="layui-form-item layui-hide" key={name}>
                <label className="layui-form-label">{item.name}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <input type="hidden" name={item.inputName || name} autoComplete="off"
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           value={val}/>
                </div>
            </div>
        )
    }

    text(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            inputStyle = typeof item === 'object' && item.inputStyle ? item.inputStyle : (this.props.inputStyle || {}),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle} title={item.showTitle || item.title ? (item.title || item.name) : ''}>
                <label className="layui-form-label" style={labelStyle}>{typeof item === 'string' ? item : item.name} {item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <input lay-verify={verify.rule || ''} lay-reqText={verify.text || ''} type="text" style={inputStyle} name={this.fitInputName(inputName)} autoComplete="off"
                           placeholder={item.placeholder || ''}
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           value={val}
                           disabled={item.disabled !== undefined ? item.disabled : false}
                    />
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    file(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            inputStyle = typeof item === 'object' && item.inputStyle ? item.inputStyle : (this.props.inputStyle || {});
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle}>
                <label className="layui-form-label" style={labelStyle}>{typeof item === 'string' ? item : item.name}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    {
                        item.original ? (
                            <input ref={item.ref} multiple={item.multiple !== undefined ? item.multiple : false} type="file" style={inputStyle} onChange={e => item.change && item.change(e, this)} name={this.fitInputName(inputName)} autoComplete="off"
                                   value={val}/>
                        ) : (
                            <File
                                src={val}
                                inputName={name}
                                btnClass={'fileBtn'+md5(name)+this.generateRandomId()}
                                handle={this.uploadFile.bind(this)}
                                exts={item.exts || []}
                                choosed={(file) => {
                                    item.choosed && item.choosed(this, file);
                                }}
                            />
                        )
                    }

                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    number(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            inputStyle = typeof item === 'object' && item.inputStyle ? item.inputStyle : (this.props.inputStyle || {}),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle} title={item.showTitle || item.title ? (item.title || item.name) : ''}>
                <label className="layui-form-label" style={labelStyle}>{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <input lay-verify={verify.rule || ''} lay-reqText={verify.text || ''} type="number" style={inputStyle} name={this.fitInputName(inputName)} autoComplete="off"
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           disabled={item.disabled !== undefined ? item.disabled : false}
                           value={val}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    password(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            inputStyle = typeof item === 'object' && item.inputStyle ? item.inputStyle : (this.props.inputStyle || {}),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle}>
                <label className="layui-form-label" style={labelStyle}>{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <input lay-verify={verify.rule || ''} lay-reqText={verify.text || ''} type="password" style={inputStyle} name={this.fitInputName(inputName)} autoComplete="off"
                           className="layui-input" onChange={e => this.onChangeModel(e)}
                           disabled={item.disabled !== undefined ? item.disabled : false}
                           value={val}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    textarea(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            inputStyle = typeof item === 'object' && item.inputStyle ? item.inputStyle : (this.props.inputStyle || {}),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle} title={item.showTitle || item.title ? (item.title || item.name) : ''}>
                <label className="layui-form-label"  style={labelStyle}>{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <textarea lay-verify={verify.rule || ''}
                              lay-reqText={verify.text || ''}
                              name={this.fitInputName(inputName)}
                              style={inputStyle}
                              className="layui-textarea"
                              onChange={e => this.onChangeModel(e)}
                              disabled={item.disabled !== undefined ? item.disabled : false}
                              value={val}/>
                </div>
                {
                    this.tip(item.tip)
                }
            </div>
        )
    }

    image(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            labelStyle = typeof item === 'object' && item.labelStyle ? item.labelStyle : (this.props.labelStyle || {}),
            itemStyle = typeof item === 'object' && item.itemStyle ? item.itemStyle : (this.props.itemStyle || {}),
            imageStyle = typeof item === 'object' && item.imageStyle ? item.imageStyle : (this.props.imageStyle || {});
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} style={itemStyle}>
                <label className="layui-form-label" style={labelStyle}>{item.name}</label>
                <div className="layui-input-block">
                    <Image src={val}
                           style={imageStyle}
                           canDel={item.canDel !== undefined ? item.canDel : true}
                           inputName={name}
                           btnClass={'imgBtn'+md5(name)+this.generateRandomId()}
                           handle={this.uploadShowPic.bind(this)}
                           max={item.max !== undefined ? item.max : '512K'}
                           tipExtentions={() => {
                               if (item.tip) {
                                   return (
                                       <div style={{
                                           height: '38px',
                                           lineHeight: '38px',
                                           marginLeft: '10px',
                                           float: 'left',
                                           color: '#999'
                                       }}>{item.tip}</div>
                                   )
                               }
                           }}
                    />
                </div>
            </div>
        )
    }

    slide(item, key, val, inputName) {
        let name = this.fitInputName(inputName);
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <Slides
                        list={val}
                        origin={item.origin !== undefined ? item.origin : false}
                        handleList={this.handleSlides.bind(this)}
                        btnClass={'slideBtn'+md5(name)+this.generateRandomId()}
                        max={item.max !== undefined ? item.max : '512K'}
                        inputName={item.inputName || (name + '[]')}
                        tipExtentions={() => {
                            if (item.tip) {
                                return (
                                    <div style={{
                                        height: '38px',
                                        lineHeight: '38px',
                                        marginLeft: '10px',
                                        float: 'left',
                                        color: '#999'
                                    }}>{item.tip}</div>
                                )
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    video(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            _this = this;
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <Video
                        canDel={true}
                        src={val}
                        btnClass={md5(this.generateRandomId()+name)}
                        handle={(video, name) => {
                            _this.handelVideo(video, name, item);
                        }}
                        inputName={name}
                        tipExtentions={() => {
                            return _this.tip(item.tip)
                        }}
                    />
                </div>
            </div>
        )
    }

    selectFromArray(item) {
        return item.data.map((val, index) => {
            if (item.option) {
                return (
                    <option key={index} value={val[item.option[0]]}>{val[item.option[1]]}</option>
                )
            }
            return (
                <option key={index} value={index}>{val}</option>
            )
        })
    }

    selectFromObject(item) {
        return Object.keys(item.data).map((key, index) => {
            return (
                <option key={index} value={key}>{item.data[key]}</option>
            )
        })
    }

    select(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name} title={item.showTitle || item.title ? (item.title || item.name) : ''}>
                <label className="layui-form-label">{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    <select lay-verify={verify.rule || ''} disabled={item.disabled !== undefined ? item.disabled : false} name={this.fitInputName(name)} lay-filter={'field'+name} value={val} disabled={item.disabled !== undefined ? item.disabled : false}>
                        {
                            item.data instanceof Array ? this.selectFromArray(item) : this.selectFromObject(item)
                        }
                    </select>
                </div>
            </div>
        )
    }

    editor(item, key, val, inputName) {
        let name = this.fitInputName(inputName),
            hide = item.hide,
            style = {};
        if (typeof hide === 'function') {
            style.display = hide(this.state, inputName) ? 'none': 'block';
        }
        return (
            <div className="layui-form-item" key={name} style={style}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <Editor
                        width={item.width}
                        height={item.height}
                        contentChange={this.editorContentChange.bind(this)}
                        content={val}
                        inputName={name}
                        width={item.width}
                    />
                </div>
            </div>
        )
    }

    radioFromArray(item, key, value, inputName) {
        return item.data.map((val, index) => {
            if (item.option) {
                return (
                    <input disabled={item.disabled !== undefined ? item.disabled : false} key={index} type="radio" name={inputName} lay-filter={'field'+inputName} checked={val[item.option[0]] === value} value={val[item.option[0]]} title={val[item.option[1]]} />
                )
            }
            return (
                <input key={index} disabled={item.disabled !== undefined ? item.disabled : false} type="radio" name={inputName} lay-filter={'field'+inputName} checked={index === Number(value)} value={index} title={val} />
            )
        })
    }

    radioFromObject(item, k, val, inputName) {
        return Object.keys(item.data).map((key, index) => {
            return (
                <input disabled={item.disabled !== undefined ? item.disabled : false} key={index} type="radio" name={inputName} checked={key === val} lay-filter={'field'+inputName} value={key} title={item.data[key]} />
            )
        })
    }

    radio(item, key, val, inputName) {
        let name = this.fitInputName(inputName);
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} key={name}>
                <label className="layui-form-label" style={item.labelStyle || (this.props.labelStyle || {})}>{item.name}</label>
                <div className={item.inputBlock ? 'layui-input-block' : "layui-input-inline"}>
                    {
                        item.data instanceof Array ? this.radioFromArray(item, key, val, name) : this.radioFromObject(item, key, val, name)
                    }
                </div>
            </div>
        )
    }

    choose(item) {
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"}>
                <label className="layui-form-label">{item.name}</label>
                <div className="layui-input-block">
                    <div className="user-btn">
                        <button type="button" onClick={e => item.click(this)}
                                className="layui-btn layui-btn-normal">{item.btn ? item.btn.name : ('选择'+item.name)}
                        </button>
                    </div>
                    <div className="pitches">
                        {typeof item.component === 'function' ? item.component(this.state, this) : item.component}
                    </div>
                </div>
            </div>
        )
    }

    button(item) {
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"}>
                <label className="layui-form-label"></label>
                <div className="layui-input-block">
                    <div className="user-btn">
                        <button type="button" onClick={e => item.click(this)}
                                className="layui-btn layui-btn-normal">{item.name}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    area(item) {
        let arr = [],
            cities = this.state.cities,
            areas = this.state.areas,
            title = ['--请选择省--', '--请选择市--', '--请选择地区--'],
            value = item.value instanceof Array ? item.value[0] : item.value,
            name = item.value instanceof Array ? item.value[1] : item.value,
            data = [item.data, cities, areas],
            verify = item.verify || {};

        for(let k in item.fields) {
            let index= Number(k),
                field = item.fields[k],
                fieldValue = this.state.model[field];

            let it = (
                <div className="layui-input-inline">
                    <select lay-verify={verify.rule || ''} lay-reqText={verify.text || ''} name={field} lay-search={""} lay-filter={"area"+field} value={fieldValue}>
                        <option value="">{title[index]}</option>
                        {
                            (data[index] || []).map((val, index) => {
                                return (
                                    <option key={index} value={val[value]}>{val[name]}</option>
                                )
                            })
                        }
                    </select>
                </div>
            )
            arr.push(it);
        }
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"}>
                <label className="layui-form-label">{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                {arr}
            </div>
        )
    }

    date(item, key, value, inputName) {
        let name = this.fitInputName(inputName),
            verify = item.verify || {};
        return (
            <div className={item.formItem ? "layui-form-item" : "layui-inline"} title={item.showTitle || item.title ? (item.title || item.name) : ''}>
                <label className="layui-form-label">{item.name}{item.dot ? <span className={"dot"}>*</span> : ''}</label>
                <div className="layui-input-inline">
                    <input lay-verify={verify.rule || ''} lay-reqText={verify.text || ''} type="text" className={"layui-input"} readOnly={true} name={name} id={'date'+name} value={value}/>
                </div>
            </div>
        )
    }

    itemComponent(item, key, value, inputName) {
        let show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show;
            return (showType === 'function' && show(this.state)) || (showType !== 'function' && show) ? (
                <div className={item.formItem ? "layui-form-item" : "layui-inline"}>
                    <label className="layui-form-label">{item.name || ''}</label>
                    <div className="layui-input-block">
                        {
                            typeof item.component === 'function' ? item.component(this.state, inputName) : item.component
                        }
                    </div>
                </div>
            ) : '';
        }
    }

    component(item) {
        return item.component;
    }

    list(item, key, value, inputName) {
        value = value || [];
        let fields = item.fields,
            show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show;
            return (showType === 'function' && show(this.state)) || (showType !== 'function' && show) ?
                (
                    <div style={item.style || {}}>
                        {
                            value.map((row, i) => {
                                let deleteObj = typeof item.delete === 'object' ? item.delete : {show:item.delete},
                                    deleteBtn,
                                    deleteShow = typeof deleteObj.show === 'function' ? deleteObj.show(i) : deleteObj.show;
                                if (deleteShow) {
                                    deleteBtn = (
                                        <div className={"layui-input-block "+style.closeBox}>
                                            <button title={"移除此项"} className={style.closeBtn} onClick={e => this.removeList(inputName, i)}><i className={"layui-icon layui-icon-close"}></i></button>
                                        </div>
                                    )
                                }
                                return (
                                    <div style={item.itemStyle || {}}>
                                        {
                                            deleteBtn
                                        }
                                        {
                                            Object.keys(fields).map((field, k) => {
                                                let it = fields[field],
                                                    name = (typeof it === 'object' ? it.key : '') || field;
                                                return this.handleRows(it, k * i, row[name], [inputName, i, name]);
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                : '';
        }
    }

    group(item) {
        let fields = item.fields,
            show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show,
                header = item.name ? (
                    <fieldset className="layui-elem-field layui-field-title" style={{marginTop: '20px',color: '#f00'}}>
                        <legend>{item.name}</legend>
                    </fieldset>
                ) : '';
            return (showType === 'function' && show(this.state)) || (showType !== 'function' && show) ?
                [header, Object.keys(fields).map((field, key) => {
                    let it = fields[field],
                        name = (typeof it === 'object' ? it.key : '') || field,
                        model = item.key ? this.state.model[item.key] : this.state.model,
                        value = item.key && model ? model[name] : this.state.model[name];
                    return this.handleRows(it, key, value, name);
                })]
                : ''
        }
    }

    box(item) {
        let fields = item.fields,
            show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show;

            return (showType === 'function' && show(this.state)) || (showType !== 'function' && show) ?
                (
                    <div className={"layui-form-item"}>
                        {
                            Object.keys(fields).map((field, key) => {
                                let it = fields[field],
                                    name = (typeof it === 'object' ? it.key : '') || field,
                                    model = item.key ? this.state.model[item.key] : this.state.model,
                                    value = item.key && model ? model[name] : this.state.model[name];
                                return this.handleRows(it, key, value, name, false);
                            })
                        }
                    </div>
                )
                : ''
        }
    }

    object(item, k, value, inputName) {
        let fields = item.fields,
            show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show;
            return (showType === 'function' && show(this.state)) || (showType !== 'function' && show) ?
                Object.keys(fields).map((field, key) => {
                    let it = fields[field],
                        name = (typeof it === 'object' ? it.key : '') || field,
                        model = item.key ? this.state.model[item.key] : this.state.model,
                        value = item.key && model ? model[name] : this.state.model[name];
                    return this.handleRows(it, key * k, value[name], [inputName, name]);
                })
                : ''
        }
    }

    handleRows(item, key, value, field, formItem = true) {
        if (typeof item === 'string') {
            let it = {
                name:item,
                formItem:formItem
            }

            return this.text(it, key, value, field);
        }
        let type = item.type || this.defaultType;
        let show = item.show !== undefined ? item.show : true;
        if (show) {
            let showType = typeof show;
            item.formItem = formItem;
            return (showType === 'function' && show(this.state, field)) || (showType !== 'function' && show) ? this[type](item, key, value, field) : '';
        }
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 30px 0 0'}}>
                {
                    Object.keys(this.props.fields).map((field, key) => {
                        let item = this.props.fields[field],
                            name = (typeof item === 'object' ? item.key : '') || field,
                            model = this.state.model,
                            value = model.hasOwnProperty(name) ? this.state.model[name] : null;
                        return this.handleRows(item, key, value, name);
                    })
                }
                <div className="layui-form-item layui-hide">
                    <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit" id="LAY-user-front-submit"
                           value="确认"/>
                </div>
            </div>
        );
    }

    fitInputName(fields) {
        fields = typeof fields === 'string' ? [fields] : fields;
        let name = fields[0];
        for(let k in fields) {
            if (Number(k) > 0) {
                name += '['+fields[k]+']';
            }
        }
        return name;
    }

    uploadShowPic(image, name) {
        let nameArr = this.handelName(name),
            model = this.handleNameAndValue(nameArr, image);
        this.setModel(model);
    }

    editorContentChange(content, name) {
        this.state.model[name] = content;
    }

    handelVideo(video, name, item) {
        let nameArr = this.handelName(name);
        this.state.model = this.handleNameAndValue(nameArr, video.src);
        if (item.with) {
            let withKey = typeof item.with === 'string' ? [item.with] : item.with;
            for(let k in withKey) {
                let name = withKey[k];
                nameArr[nameArr.length - 1] = name;
                this.state.model = this.handleNameAndValue(nameArr, video[name]);
            }
        }
        this.setModel(this.state.model, '', () => {
            item.change && item.change(this);
        });
    }

    onChangeModel(e) {
        this.setModel(this.handleNameAndValue(this.handelName(e.target.name), e.target.value));
    }

    handleNameAndValue(nameArr, value) {
        let model = this.state.model,
            field = nameArr[0],
            length = nameArr.length;
        if (length === 1) {
            model[field] = value
        } else if (length === 2 && nameArr[1]) {
            model[field][nameArr[1]] = value;
        } else if (length === 3 && nameArr[1] && nameArr[2]) {
            model[field][nameArr[1]][nameArr[2]] = value;
        }
        return model;
    }

    handelName(name) {
        return name.replace(/\]/g, '').split('[');
    }

    removeList(inputName, index) {
        let model = this.state.model,
            list = model[inputName],
            rows = [];
        for(let k in list) {
            if (Number(k) !== Number(index)) {
                rows.push(list[k]);
            }
        }
        model[inputName] = rows;
        this.setModel(model);
    }

    handleSlides(index, list, inputName) {
		 
        let nameArr = this.handelName(inputName),
            model = this.handleNameAndValue([nameArr[0]], list);
        this.setModel(model);
    }

    uploadFile(path, name) {
        let nameArr = this.handelName(name),
            model = this.handleNameAndValue(nameArr, path);
        this.setModel(model);
    }

}

export default Form;
