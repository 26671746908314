import Base from "../../../base";
import DeviceListLayout from "../layout";

export default class DeviceList extends Base
{
    render() {
        return (
            <DeviceListLayout
                oprationShow={true}
            />
        );
    }
}
