import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class SimService extends BaseService
{
    requestUrls = {
        url:'/device/list',
        add:'/device/add',
		export:'/device/export',
    }

   excelFields = ['id',  'model', 'deviceNum', 'made'];
	exportTitle= {
	'id':'序号', 
	'company':'所属公司', 
	'model':'型号', 
	'deviceNum':'设备号', 
	'made':'厂商', 
	'statusDup':'状态', 
	'createdAt':'入库时间',
	'proddedAt':'出库时间',
	'logDup':'日志记录',
	};
	
	 
	getExcelTitles() {
	    let titles = [];
	    Object.keys(this.exportTitle).forEach(item => {
	        titles.push(this.exportTitle[item]);
	    })
	    return titles;
	}

    status = ['未出库', '已出库'];
	deviceStatusName = ['未安装','已安装','已撤机','已更换'];
    appends = ['statusDup','deviceStatusDup'];

    add(data, suc, err) {
        let rules = [
                ['data', 'required|array', '没有数据上传|没有数据上传'],
                ['data.*.model', 'required', '型号不能为空'],
                ['data.*.deviceNum', 'required', '设备号不能为空'],
                ['data.*.made', 'required', '厂商不能为空'],
            ],
            validator = new Validator();
        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        data.type = 2;
        this.post(
            this.requestUrls.add,
            data,
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
	
	getDeviceStatusDupAttribute() {
	    return this.deviceStatusName[this.attributes['device_status']];
	}
	
	
	export(ids, suc) {
	    this.post(
	        this.requestUrls.export,
	        {ids:ids},
	        suc
	    )
	}
	
}
