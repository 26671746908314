import Base from "../base";
import { Fragment } from 'react';
import TableEmpty from "../../components/tableempty";
import $ from 'jquery';

class ListLayout extends Base
{
    constructor(props) {
        super(props);

        let suprops = $.extend({}, props);
        if (suprops.props) {
            for(let k in suprops.props) {
                suprops[k] = suprops.props[k];
            }
        }

        this.show = suprops.show !== undefined ? suprops.show : true; //初始化是否显示
        this.autoload = suprops.autoload !== undefined ? suprops.autoload : true; //初始化是否自动加载数据

        let list = suprops.list || []; // 初始化列表原数组
        let data = (this.dataInit && this.dataInit()) || {};
        data.list = data.list || list;//初始化列表容器

        let state = (this.stateInit && this.stateInit()) || {}; //读取state存储控件初始配置

        state.data = data;//装列表容器载入state
        let search = state.search || {};//初始化筛选条件及分页配置

        if (suprops.search) {
            for(var k in suprops.search) {
                search[k] = suprops.search[k];
            }
        }

        state.search = search;
        state.listTitles = []; //初始化列表原始容器
        state.listValues = []; //初始化列表数据值容器
        state.searchSwitch = true; //初始化列表搜索控件开关
        state.orderBy = state.orderBy || [];
        state.requestParams = suprops.requestParams || {};

        this.state = state;

        //初始化搜索控件配置
        this.search = {
            show: suprops.searchShow !== undefined ? suprops.searchShow : true,
            fields: suprops.searchFields || [],
            hidden:suprops.searchHidden || [],
            values: []
        }

        //初始化列表列表配置
        this.list = {
            fields:(this.fieldsInit && this.fieldsInit()) || {},
            visibles:suprops.visibles || [],
            hidden:suprops.hidden || [],
            canCheck:suprops.canCheck !== undefined ? suprops.canCheck : false,
            checkMany:suprops.checkMany !== undefined ? suprops.checkMany : false,
            inputName:suprops.inputName !== undefined ? suprops.inputName : 'data[]'
        }

        //初始化操作控件配置
        this.opration = {
            show:suprops.oprationShow !== undefined ? suprops.oprationShow : true,
            fields:suprops.oprationFields || []
        }

        //初始化分页配置
        this.paging = {
            show:suprops.paging !== undefined ? suprops.paging : true,
            id:suprops.pageId || 'layPage',
            page:suprops.page || 1,
            perPage:suprops.perPage || 15,
            runout:suprops.pagingRunout !== undefined ? suprops.pagingRunout : false,
            extention: suprops.pagingExtentionShow !== undefined ? suprops.pagingExtentionShow : false
        }
        this.paging[this.paging.id] = {
            page:this.paging.page,
            perPage:this.paging.perPage
        };

        //初始化填充空表格
        this.empty = {
            fill:suprops.emptyFill !== undefined ? suprops.emptyFill : true
        }

        //始始化添加按钮控件配置
        this.btns = {
            show:suprops.btnShow !== undefined ? suprops.btnShow : true,
            fields:suprops.btnFields || []
        }

        // 初始化checkbox配置
        this.checkbox = {
            init:suprops.checkboxInit !== undefined ? suprops.checkboxInit : true,
            initElem:suprops.checkboxInitElem !== undefined ? suprops.checkboxInitElem : false
        }

        // 初始化样式
        this.style = {
            layuiFluid:suprops.layuiFluid !== undefined ? suprops.layuiFluid : true,
            layuiCardBody:suprops.layuiCardBody !== undefined ? suprops.layuiCardBody : true,
            layForm:suprops.layForm !== undefined ? suprops.layForm : true,
            layuiCardHeader: suprops.layuiCardHeader !== undefined ? suprops.layuiCardHeader : true,
            layuiCardBodyStyle:suprops.layuiCardBodyStyle !== undefined ? suprops.layuiCardBodyStyle : {},
            tableStyle:suprops.tableStyle !== undefined ? suprops.tableStyle : {}
        }

        //初始化排序配置
        this.sortShow = {
            props:suprops.sortShow,
            can: false
        }
    }

    //加载数据
    loadData(conf = {}) {
        let _this = this;
        //自动加载列表
        if (this.show) {
            if (this.autoload) {
                conf.success = (response) => {
                    //组装列表数据
                    _this.setState({
                        data:response
                    }, () => {
                        _this.handleList();
                        _this.listInit();
                    })
                }
                this.getList && this.getList(conf);
            } else  {
                //组装列表数据
                _this.handleList();
                _this.listInit();
            }
        }
    }

    getList(conf) {
        return this.getPage(conf);
    }

    setData(data, func) {
        func = func || function () {};
        this.setState({
            data:data
        }, func)
    }

    setList(list, func) {
        func = func || function () {};
        let data = this.state.data;
        data.list = list;
        this.setState({
            data:data
        }, func)
    }

    handleList() {
        if (this.paging.show && this.paging.runout && this.state.data.pages <= 1) {
            this.paging.show = false;
        }
        this.props.handleList && this.props.handleList(this.state.data);
    }

    //初始化标题和数据
    componentWillMount() {
        this.getListTitles();
        this.loadData();
    }

    //组装列表
    listInit() {
        this.getListValues();
    }

    //组装搜索控件
    getSearchFunc() {
        var searches = [],
            config = (this.searchInit && this.searchInit()) || {}, // 读取搜索配置
            searchFields = this.search.fields;

        if (! searchFields.length) {
            for(var k in config) {
                searchFields.push(k);
            }
        }
        if (this.search.show) {
            for(let k in searchFields) {
                let field = searchFields[k],
                    func = config[field],
                    val;
                if (this.search.hidden.indexOf(field) === -1 && func) {
                    if (typeof func === 'function') {
                        func = func.bind(this);
                        val = func();
                    } else {
                        val = func;
                    }
                    //装载搜索控件
                    searches.push(val);
                }
            }
        }
        return searches;
    }

    getSearchExtentions() {
        var searches = [],
            config = this.searchExtentions ? this.searchExtentions() : [],
            searchFields = this.search.fields;

        for(var k in config) {
            if (searchFields.indexOf(k) < 0) {
                searchFields.push(k);
            }
        }

        if (this.search.show && config) {
            for(let k in searchFields) {
                var func = config[searchFields[k]],
                    value;
                if (func) {
                    if (typeof func === 'function') {
                        func = func.bind(this);
                        value = func();
                    } else {
                        value = func;
                    }
                    //装载搜索控件
                    searches.push(value);
                }
            }
        }
        return searches;
    }

    //装载配置可显示列表字段
    getVisiblesFields() {
        var fields = this.list.fields,
            visibles = this.list.visibles,
            hidden = this.list.hidden,
            data = [];
        if (! visibles.length) {
            for(let k in fields) {
                visibles.push(k);
            }
        }
        for(let k in visibles) {
            var val = visibles[k];
            if (hidden.indexOf(val) < 0) {
                data.push(val);
            }
        }
        return data;
    }

    // 装载标题
    getListTitles() {
        var fields = this.list.fields,
            visibles = this.getVisiblesFields(),
            titles = [];
        //加载已配置显示字段
        for(var k in visibles) {
            var field = visibles[k];
            if (fields.hasOwnProperty(field)) {
                var value = fields[field],
                    val;
                if (typeof value === 'function') {
                    value = value.bind(this);
                    val = value()['title'];
                } else {
                    val = value;
                }
                titles.push(val);
            }
        }

        //加载操作
        var opration = this.getOpration({}, true);
        if (opration) {
            titles.push(opration.title);
        }

        //挂载准备好标题
        this.setState({
            listTitles:titles
        })
    }

    //装载列表可显示值
    getListValues() {
        var list = this.state.data.list,
            visibles = this.getVisiblesFields(),
            fieldsArr = this.list.fields,
            values = [];
        for(var index in list) {
            var model = this.getValueModel(list[index]);
            var fields = [];
            for(let k in visibles) {
                var field = visibles[k];
                if (fieldsArr.hasOwnProperty(field)) {
                    var value = fieldsArr[field],
                        v;
                    if (typeof value === 'function') {
                        value = value.bind(this);
                        v = value(model, index, list[index])['value'];
                    } else {
                        v = model[field];
                    }
                    v = v && typeof v === 'object' ? v : (<td key={k}>{v}</td>);
                    fields.push(v);
                }
            }
            //加载操作按钮配置
            var opration = this.getOpration(model, false, index);
            if (opration) {
                fields.push(opration.value);
            }
            fields.length && values.push(fields); //将数据依次装载进列表数组
        }

        // 读取可进行排序条件
        var canSort = values.length > 1;
        if (this.sortShow.props !== false) {
            if (typeof this.sortShow.props === 'boolean') {
                if (canSort) {
                    canSort = this.sortShow.props;
                }
            }
            //如果条件可排序并且尚未加载排序,重装标题
            if ((canSort && ! this.sortShow.can) || (! canSort && this.sortShow.can)) {
                this.sortShow.can = canSort;
                this.getListTitles();
            }
        }

        //挂载准备好列表数据
        this.setState({
            listValues:values
        }, () => {
            /* global layui */
            layui.form && layui.form.render();
            if (this.list.canCheck && values.length) {
                this.checkboxInit();
            }
        })
    }

    getValueModel(model) {
        if (typeof this.props.model === 'function') {
            return this.props.model(model);
        }
        return model;
    }

    //读取操作配置
    getOpration(model, injected = false) {
        if (this.opration.show) {
            if (injected && this.oprationInit && (!model || JSON.stringify(model) === '{}')) {
                var funcString = this.oprationInit.toString();
                var preg = /[oprationInit|function]\(([a-z|A-Z]*?)\)/g;
                var match = funcString.match(preg);
                if (match) {
                    var dependModel = match[0].replace(/[oprationInit|function]\(|\)/g, '');
                    var ppreg = '/'+dependModel+'.[a-z|A-Z]+([(|)]+)/g';
                    var depandPas = funcString.match(this.evil(ppreg));
                    if (depandPas) {
                        for(var k in depandPas) {
                            var v = depandPas[k];
                            let func = v.replace(/[^a-z|A-Z|.]/g, '').split('.');
                            if (! model[func[1]]) {
                                model[func[1]] = () => {};
                            }
                        }
                    }
                }
            }
            var oprations = (this.oprationInit && this.oprationInit(model)) || undefined,
                type = typeof oprations;

            if (type === 'function') {
                return oprations(model);
            } else if (oprations && type === 'object') {
                var obj = {
                    title:oprations.title,
                    value:(<td key={this.generateRandomId()}>{oprations.value}</td>)
                };
                var values = oprations.values,
                    fields = this.opration.fields;
                if (! fields.length) {
                    for(let k in values) {
                        fields.push(k);
                    }
                }
                if (values && typeof values === 'object') {
                    var arr = [];
                    for(let k in fields) {
                        let func = values[fields[k]],val;
                        if (typeof func === 'function') {
                            func = func.bind(this);
                            val = func(model);
                        } else {
                            val = func;
                        }
                        arr.push(val)
                    }
                    if (typeof this.props.oprationExtention === 'function') {
                        arr.push(this.props.oprationExtention(model));
                    }
                    obj.value = (<td key={this.generateRandomId()} style={this.props.oprationStyle || {}}>{arr}</td>);
                }
                return obj;
            }
        }
    }

    //读取扩展按钮配置
    getBtnExtentions() {
        if (this.btns.show) {
            var exts = (this.btnExtentions && this.btnExtentions.bind(this) && this.btnExtentions()) || {},
                fields = this.btns.fields,
                btns = [];
            if (! fields.length) {
                for(var k in exts) {
                    fields.push(k);
                }
            }
            for(let k in fields) {
                var func = exts[fields[k]],val;
                if (typeof func === 'function') {
                    func = func.bind(this);
                    val = func();
                } else {
                    val = func;
                }
                if (val) {
                    btns.push(val);
                }
            }
            if (btns.length) {
                return (
                    <div key={this.generateRandomId(10)} style={{paddingBottom: '10px'}}>{btns}</div>
                )
            }
        }
    }

    getPagingExtentions() {
        return this.pagingExtention && this.pagingExtention.bind(this) && this.pagingExtention();
    }

    getTableHeader() {

    }

    //接收到props数据时自启动
    componentWillReceiveProps(props) {
        let obj = {},
            suprops = $.extend({}, props);;
        if (suprops.props) {
            for(let k in suprops.props) {
                suprops[k] = suprops.props[k];
            }
        }
        if (suprops.search) {
            obj.search = suprops.search;
        }
        if (suprops.data) {
            obj.data = suprops.data;
        }
        if (suprops.list) {
            obj.data = this.state.data;
            obj.data.list = suprops.list;
        }

        if (suprops.show !== undefined) {
            this.show = suprops.show;
        }
        if (suprops.autoload !== undefined) {
            this.autoload = suprops.autoload;
        }

        if (suprops.perPage) {
            this.paging.pageSize = suprops.perPage;
        }
        if (suprops.page) {
            this.paging.page = suprops.page;
        }

        if (JSON.stringify(obj) !== '{}') {
            this.setState(state =>{
                return obj;
            }, () => {
                this.willReceiveProps && this.willReceiveProps(props);
                if (suprops.shouldUpdate !== undefined) {
                    let should = typeof suprops.shouldUpdate === 'function' ? suprops.shouldUpdate(this.props, suprops) : suprops.shouldUpdate
                    should && this.loadData();
                } else {
                    this.loadData();
                }
            })
        }
    }

    render() {
        return (
            this.show ?
                <div className={this.style.layuiFluid ? "layui-fluid" : ''}>
                    <div className={"layui-card"+(this.style.layForm ? ' layui-form':'')}>
                        {
                            this.search.show ? //筛选表单
                                <div className={this.style.layuiCardHeader ? "layui-card-header layuiadmin-card-header-auto" : ''}>
                                    <div className={"layui-form-item"}>
                                        {
                                            this.getSearchFunc().map((val, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {val}
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    {this.getSearchExtentions()}
                                </div>
                                :''
                        }
                        <div className={this.style.layuiCardBody ? "layui-card-body" : ''} style={this.style.layuiCardBodyStyle}>
                            {this.getBtnExtentions()}
                            {this.getTableHeader()}
                            <div style={{overflowX:'auto'}}>
                                <table cellSpacing="0" cellPadding="0" border="0" className="layui-table" style={this.style.tableStyle}>
                                    <thead>
                                    {this.props.listTitleHeaderComponent !== undefined ? this.props.listTitleHeaderComponent : undefined}
                                    <tr className="layui-table-click">
                                        {//标题传成html实体
                                            this.state.listTitles.map((val, index) => {
                                                if (typeof val === 'object' && (val.type === 'th' || val.type === 'td')) {
                                                    return val;
                                                }
                                                return (
                                                    <th key={index}>{val}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {//列表数据展示
                                        this.state.listValues.map((val, index) => {
                                            return (
                                                <tr key={index}>
                                                    {
                                                        val.map((v, i) => {
                                                            return <Fragment key={i}>{v}</Fragment>;
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        //填充空表格
                                        <TableEmpty colSpan={this.state.listTitles.length} isShow={this.empty.fill && !Boolean(this.state.listValues.length)} />
                                    }
                                    </tbody>
                                </table>
                            </div>

                            {//分页
                                this.paging.show ?
                                    <div className="paging">
                                        {this.paging.extention ? this.getPagingExtentions() : ''}
                                        <div className="paging-r">
                                            <div id={this.paging.id}></div>
                                        </div>
                                    </div>
                                    :''
                            }
                            {//表单提交按钮
                                this.list.canCheck ?
                                    <div className="layui-form-item layui-hide">
                                        <input type="button" lay-submit="" lay-filter="LAY-user-front-submit" id="LAY-user-front-submit" value="确认" />
                                    </div>
                                    :''
                            }
                        </div>
                    </div>
                </div>
                :''
        );
    }

    //重载当前页
    pageReload(conf = {}) {
        var paging = this.getPaging();
        conf.perPage = paging.perPage;
        conf.page = paging.page;
        this.loadData(conf);
    }

    searchSwitch() {
        this.setState({
            searchSwitch:!this.state.searchSwitch
        })
    }

    generateRandomId(length){ //length是你的id的长度，可自定义
        return Math.random().toString(36).substr(3,length);
    }

    //设置分页
    setPaging(conf) {
        var id = this.paging.id,
            paging = this.paging[id];
        for(var k in conf) {
            paging[k] = conf[k];
        }
        this.paging[id] = paging;
    }

    //获取分页
    getPaging(key) {
        var id = this.paging.id,
            paging = this.paging[id];
        if (key) {
            return paging[key];
        }
        return paging;
    }

    evil(fn) {
        var Fn = Function;  //一个变量指向Function，防止有些前端编译工具报错
        return new Fn('return ' + fn)();
    }
}
export default ListLayout;
