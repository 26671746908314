import Base from "../../base";
import UserService from "../../../services/user";
import Detail from "../../layout/detail/detail";
import File from "../../../../../vendor/framework/file";
import BankService from "../../../services/running/bank";

class UserDetail extends Base
{
	file = new File();

	boot() {
		this.state = {
			model:{},
			type:{},
			attr:{},
			receiveBank:{},
			bank:{}
		}
	}

	service() {
		return UserService;
	}

	componentDidMount() {
		/* global layer */
		this.server.getDetail(this.getPropsParams('id'), data => {
			let facadeImg = data.information.facadeImg;
			if (facadeImg) {
				facadeImg = facadeImg.split(',');
				for(let k in facadeImg) {
					facadeImg[k] = this.file.make(facadeImg[k]);
				}
				data.information.facadeImg = facadeImg;
			}
			this.setModel(data);
			if (data.information.receiveBank) {
				this.server.getService(BankService).getDetail(data.information.receiveBank, bank => {
					this.setState({
						receiveBank:bank || {}
					})
				})
			}
			if (data.information.bank) {
				this.server.getService(BankService).getDetail(data.information.bank, bank => {
					this.setState({
						bank:bank || {}
					})
				})
			}
		});
	}

	render() {
		return (
			<Detail
				model={this.state.model}
				fields={[
					{
						title:'基本信息',
						fields:{
							'information.name':'商户名称',
							'information.code':'编号',
							'information.type.name':'商户类型',
							'information.attr.name':'商户属性',
							position:{name:'所在地区', value: model => { let information = model.information || {}; return information.province ? (information.province + information.city + information.area) : ''; } },
							'information.bankAddress':{name:'银行商户地址', block: true},
							'information.address':{name:'商户地址', block: true},
							location:{name:'具体位置', block: true, type:'itemComponent', component: () => {
									return (
										<button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showLocation(e)}>
											<i className="layui-icon layui-icon-list"></i>
											查看
										</button>
									)
								}},
							'information.lendRate':'费率(借)',
							'information.loanRate':'费率(贷)',
							'information.outRate':'费率(外)',
							'information.originRate':'费率(本)',
							'information.specialCoast':'特殊计费',
							'information.level':'商户等级',
							'information.expansion':'拓展人',
							'information.expansionCoast':'拓展费用',
							'information.platform':'所属平台',
							'information.bankAgent':'银行经办人',
							'information.expansionType':'拓展方式',
							'information.channel':'渠道',
							'information.transactionControl':'交易控制',
							'information.receiveDate':{name:'收单日期', type:'date'},
							'information.postcode':'邮政编码',
							'information.serialNumber':'申请序号',
							'information.risk':'风险等级',
							'information.salesman':'业务员',
							'information.salesmanNum':'业务员工号',
							'information.externalChannels':'外部渠道',
							'information.clientManagerPhone':'客户经理电话',
							'information.district':'区域',
							'information.facadeImg':{name:'门面照', type:'slide', block: true,},
							statusDup:'账号状态',
							'information.status':{
								name:'申请商户状态',
								value: model => {
									let information = model.information || {};
									return this.server.auditStatus[information.status]
								}},
							'information.qrcode':{name:'二维码', type:'image'},
							'information.remark':{name:'备注'}
						}
					},
					{
						title:'联系人信息',
						fields:{
							'information.corporate':'法人代表',
							'information.corporateIdcode':'证件号',
							'information.corporateValidityStartDate':{name:'有效期开始', type:'date'},
							'information.corporateValidityEndDate':{name:'有效期结束', type:'date'},
							'information.corporatePhone':'法人手机',
							'information.corporateTel':'法人固话',
							'information.corporateFax':'法人传真',
							'information.contact':'联系人',
							'information.tel':'联系电话',
						}
					},
					{
						title:'公司信息',
						fields: {
							'information.isCompany': {
								name: '是否所属公司', value: model => {
									let information = model.information || {};
									return this.server.isCompany[information.isCompany]
								}
							},
							'information.company.name':{name:'所属公司', show: model => { return model.information && model.information.company ? model.information.company.name : ''}}
						}
					},
					{
						title:'银行信息',
						fields:{
							receiveBank:{name:'收单银行', value: this.state.receiveBank.name},
							bank:{name:'开户银行', value: this.state.bank.name},
						}
					},
					{
						title:'其他信息',
						fields:{
							'information.authorized':'被授权人',
							'information.authorizedIdcode':{name:'被授权人身份证'},
							'information.authorizedValidityStartDate':{name:'有效期开始'},
							'information.authorizedValidityEndDate':{name:'有效期结束'},
							'information.registerDate':{name:'登记日期'},
							'information.startDate':{name:'启用日期'},
							'information.aliCoastRate':{name:'支付宝费率'},
							'information.wechatCoastRate':{name:'微信费率'}
						}
					},
					{
						title:'终端信息',
						fields:{
							unSend:'待派装机',
							sent:'已派装机',
							installed:'已装机',
							unrepeal:'已派撤机',
							repealed:'已撤机',
						}
					}
				]}
			/>
		);
	}

	showLocation() {
		let model = this.state.model;
		this.layOpen({
			title:model.information.name + ' 的所在位置',
			content:`/admin/map?longitude=${model.information.longitude}&latitude=${model.information.latitude}`,
			btn:['确定'],
			yes:(index) => {
				layer.close(index);
			}
		})
	}
}

export default UserDetail;
