import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class BankService extends BaseService
{
    requestUrls = {
        url:'/running/bank/list',
        save:'/running/bank/save',
        detail:'/running/bank/detail',
        delete:'/running/bank/delete'
    }

    status = ['禁用', '启用'];

    type = ['总行', '分行', '支行'];

    appends = ['statusDup', 'typeDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写银行名称'],
                ['account', 'required', '请填写账号'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getTypeDupAttribute() {
        return this.type[this.attributes['type']];
    }
}
