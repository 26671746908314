import Base from "../../../base";
import Form from "../../../layout/form";
import UserListLayout from "../../../user/layout/list";
import UserService from "../../../../services/user";
import OrderPatrolService from "../../../../services/order/patrol";
import UserDeviceListLayout from "../../../user/device/layout";
import CompanyUserListLayout from "../../../company/user/layout";
import UserDeviceService from "../../../../services/user/device";
import CompanyUserService from "../../../../services/company/user";

export default class OrderPatrolEdit extends Base {
    boot() {
        this.state = {
            model: {
                name: '',
                phone: '',
                status: 0,
				facadeImg:[],
				innerImg:[],
				deskImg:[],
				physicalImg:[],
				salesImg:[],
				signImg:[],
				result: 0,
				reason: '',
				posDeviceNum: '',
				isGeneralSign: 0,
				 
				
            },
            company: [],
            client: [],
            companyUser:[],
            device:[]
        }
        /* global layer */
    }

    service() {
        return OrderPatrolService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), data => {
			
			data.terminalCode='';
			data.deviceId=0;

			if(data.device){
			data.terminalCode =  data.device.terminalCode;
			data.deviceId =  data.device.id;	

			}

			data.salesImg=data.salesImg? data.salesImg.split(','):[];
			data.facadeImg=data.facadeImg? data.facadeImg.split(','):[];
			data.innerImg=data.innerImg? data.innerImg.split(','):[];
			data.deskImg=data.deskImg? data.deskImg.split(','):[];
			data.physicalImg=data.physicalImg? data.physicalImg.split(','):[];
			data.signImg=data.signImg? data.signImg.split(','):[];

            this.setModel(data);
            this.server.getService(UserService).getDetail(data.uid, user => {
                this.setState({
                    client: [user]
                })
            })
            if (data.device) {
                this.server.getService(UserDeviceService).getDetail({id:data.device.id}, device =>{
                    this.setState({
                        device:[device]
                    })
                })
            }
            if (data.checkUid) {
                this.server.getService(CompanyUserService).getDetail(data.checkUid, user => {
                    this.setState({
                        companyUser:[user]
                    })
                })
            }
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    // base: {
                    //     type:'group',
                    //     name:'基础信息',
                    //     fields:{
                            client: {
                                name: '商户',
                                type: 'choose',
                                component: this.client.bind(this),
                                click: this.chooseClient.bind(this)
                            },
                            device:{
                                name:'终端',
                                type:'choose',
                                component: this.device.bind(this),
                                click: this.chooseDevice.bind(this)
                            },
                            username:'联系人',
                            phone:'联系电话',
                            address:{name:'地址', type:'textarea'},
                            remark:{name:'备注(问题描述)', type:'textarea'},
                            status:{name:'状态', type:'select', data:this.server.status},
                            companyUser:{
                                name:'负责人',
                                type:'choose',
                                component: this.companyUser.bind(this),
                                click: this.chooseCompanyUser.bind(this)
                            },
                            servedAt:{name:'完成时间', type:'date', format:'datetime'},
							orders:{
								 type: 'group',
								 name:'巡检信息',
								 fields:{
									innerImg:{name:'内部照', type:'slide'},
									deskImg:{name:'前台照', type:'slide'},
									physicalImg:{name:'机身照', type:'slide'},
									salesImg:{name:'签购单', type:'slide'},
									signImg:{name:'签名照', type:'slide'},
									result:{name:'结果', type: 'radio', data: this.server.result,labelStyle:{whiteSpace: 'nowrap'}},
									reason:{name:'备注原因'},
									posDeviceNum:{name:'机身号'},
									isGeneralSign:{name:'是否已签署', type: 'radio', data: this.server.generalSign,labelStyle:{whiteSpace: 'nowrap'}},
									 
									 
								 } 
								}
							

							 
                    //     }
                    // },
                }}
            />
        );
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup', 'auditStatusDup', 'location', 'qrcode']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        let company = this.state.company[0];
        if (!company) {
            return layer.msg('请先选择所属公司!');
        }

        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }

                let client = JSON.parse(data[0]);
                this.setState( {
                    client: [client]
                })

                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    model.username = data.information.contact;
                    model.phone = data.information.tel;
                    let fields = ['address'];
                    for(let k in fields) {
                        let field = fields[k];
                        if (data.information.hasOwnProperty(field) && !model[field]) {
                            model[field] = data.information[field];
                        }
                    }
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }

    device() {
        return (
            <UserDeviceListLayout
                list={this.state.device}
                layuiFluid={false}
                layuiCardBody={false}
                paging={false}
                show={this.state.device.length}
                inputName={"deviceId"}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                autoload={false}
            />
        )
    }

    chooseDevice() {
        if (! this.state.client.length) {
            return layer.msg('请选择商户!');
        }
        let uid = this.state.client[0].id;
        this.layOpen({
            title: '选择终端',
            content: '/admin/user/device/choose/list?uid='+uid+'&status=3',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let device = JSON.parse(data[0]);
                if (device.contractor) {
                    this.server.getService(CompanyUserService).searchList(device.contractor, list => {
                        if (list.data.length) {
                            this.setState({
                                companyUser:[list.list[0]]
                            })
                        }
                    })
                }
                this.setState( state => {
                    let model = state.model;
                    model.deviceId = device.deviceId;
                    return {
                        device: [device],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    companyUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.companyUser}
                show={this.state.companyUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'cuid'}
            />
        )
    }

    chooseCompanyUser() {
        this.layOpen({
            title: '选择负责人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.cuid = user.id;
                    return {
                        companyUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }
}
