import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class DeviceTypeService extends BaseService
{
    requestUrls = {
        url:'/device/type/list',
    }
  
	getList(id, suc) {
	    this.post(
	        this.requestUrls.url,
	        {id:id},
	        suc
	    )
	}
	
	
 
	
 
}
