import Base from "../../../base";
import KeybordListLayout from "../layout";

export default class KeybordChooseList extends Base
{
    render() {
        return (
            <KeybordListLayout
                canCheck={true}
                btnShow={false}
                oprationShow={false}
            />
        );
    }
}
