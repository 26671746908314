import Base from "../../../base";
import Form from "../../../layout/form";
import UserDeviceService from "../../../../services/user/device";
import PosListLayout from "../../../device/pos/layout";
import KeybordListLayout from "../../../device/keybord/layout";
import SimListLayout from "../../../device/sim/layout";
import UserListLayout from "../../layout/list";
import UserService from "../../../../services/user";
import BankService from "../../../../services/running/bank";
import BankListLayout from "../../../running/bank/layout";
import { Fragment } from 'react';
import PosService from "../../../../services/device/pos";

export default class UserDeviceEdit extends Base {
    boot() {
        this.state = {
            model: {
                status:0
            },
            client: [],
            installUser:[],
            checkUser:[],
            receiveBank:[],
            bank:[],
            pos:[],
            keyboard:[],
            sim:[]
        }
    }

    service() {
        return UserDeviceService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail({id:this.getPropsParams('id')}, data => {
            this.setModel(data);
            if (data.posId) {
                this.server.getService(PosService).getDetail(data.posId, pos => {
                    this.setState({
                        pos:[pos]
                    })
                })
            }
            if (data.keyboardId) {
                this.server.getService(PosService).getDetail(data.keyboardId, keyboard => {
                    this.setState({
                        keyboard:[keyboard]
                    })
                })
            }
            if (data.simId) {
                this.server.getService(PosService).getDetail(data.simId, sim => {
                    this.setState({
                        sim:[sim]
                    })
                })
            }
            this.server.getService(UserService).getDetail(data.uid, user => {
                this.setState({
                    client: [user]
                })
            })
            if (data.receiveBank) {
                this.server.getService(BankService).getDetail(data.receiveBank, bank => {
                    this.setState({
                        receiveBank:[bank]
                    })
                })
            }
            if (data.bank) {
                this.server.getService(BankService).getDetail(data.bank, bank => {
                    this.setState({
                        bank:[bank]
                    })
                })
            }
        })
    }

    service() {
        return UserDeviceService;
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    base:{
                        type:"group",
                        name:'基本信息',
                        fields:{
                            client: {
                                name: '商户',
                                type: 'choose',
                                component: this.client.bind(this),
                                click: this.chooseClient.bind(this)
                            },
                        }
                    },
                    user:{
                        type:"group",
                        name:'联系人信息',
                        fields:{
                            box:{
                                type:'box',
                                fields:{
                                    corporate:'法人代表',
                                    corporateIdcode:{name:'法人证件号', title:'法人代表证件号', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}},
                                    corporateValidityStartDate:{name:'有效期开始', type:'date', title:'法人代表证件号有效期开始'},
                                    corporateValidityEndDate:{name:'有效期结束', type:'date', title:'法人代表证件号有效期结束'},
                                    corporatePhone:'法人手机',
                                    corporateTel:'法人固话',
                                    corporateFax:'法人传真',
                                    username:'联系人',
                                    phone:'联系电话',
                                }
                            },
                            address:{name:'安装地址', type:'textarea'},
                            bankAddress:{name:'银行商户地址', type:'textarea'}
                        }
                    },
                    terminal:{
                        type:"group",
                        name:'终端信息',
                        fields:{
                            terminalCode:'终端号',
                            pos:{
                                name:'POS机',
                                type:'choose',
                                click: this.choosePos.bind(this),
                                component:(
                                    <Fragment>
                                        <PosListLayout
                                            autoload={false}
                                            list={this.state.pos}
                                            show={this.state.pos.length}
                                            paging={false}
                                            btnShow={false}
                                            oprationShow={false}
                                            layuiFluid={false}
                                            layuiCardBody={false}
                                            searchShow={false}
                                            hidden={['proddedAt']}
                                            inputName={"posId"}
                                        />
                                        <div style={{display:'none'}}>
                                            {
                                                this.state.pos.map((val, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <input type="text" name={"posId"} value={val.id}/>
                                                            <input type="text" name={"posModel"} value={val.model}/>
                                                            <input type="text" name={"posDeviceNum"} value={val.deviceNum}/>
                                                            <input type="text" name={"posMade"} value={val.made}/>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Fragment>

                                )
                            },
                            keyboard:{
                                type:'choose',
                                name:'键盘',
                                click: this.chooseKeyboard.bind(this),
                                component:(
                                    <Fragment>
                                        <KeybordListLayout
                                            autoload={false}
                                            list={this.state.keyboard}
                                            show={this.state.keyboard.length}
                                            paging={false}
                                            btnShow={false}
                                            oprationShow={false}
                                            layuiFluid={false}
                                            layuiCardBody={false}
                                            searchShow={false}
                                            hidden={['proddedAt']}
                                            inputName={"keyboardId"}
                                        />
                                        <div style={{display:'none'}}>
                                            {
                                                this.state.keyboard.map((val, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <input type="text" name={"keyboardId"} value={val.id}/>
                                                            <input type="text" name={"keyboardModel"} value={val.model}/>
                                                            <input type="text" name={"keyboardDeviceNum"} value={val.deviceNum}/>
                                                            <input type="text" name={"keyboardMade"} value={val.made}/>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Fragment>
                                )
                            },
                            sim:{
                                type:'choose',
                                name:'SIM卡',
                                click: this.chooseSim.bind(this),
                                component:(
                                    <Fragment>
                                        <SimListLayout
                                            autoload={false}
                                            list={this.state.sim}
                                            show={this.state.sim.length}
                                            paging={false}
                                            btnShow={false}
                                            oprationShow={false}
                                            layuiFluid={false}
                                            layuiCardBody={false}
                                            searchShow={false}
                                            hidden={['proddedAt']}
                                            inputName={"simId"}
                                        />
                                        <div style={{display:'none'}}>
                                            {
                                                this.state.sim.map((val, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <input type="text" name={"simId"} value={val.id}/>
                                                            <input type="text" name={"simModel"} value={val.model}/>
                                                            <input type="text" name={"simDeviceNum"} value={val.deviceNum}/>
                                                            <input type="text" name={"simMade"} value={val.made}/>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Fragment>
                                )
                            },

                            receiveBank:{name:'收单银行', type:'choose', component: this.receiveBank.bind(this), click: this.chooseReceiveBank.bind(this)},
                            bank:{name:'开户银行', type:'choose', component: this.bank.bind(this), click: this.chooseBank.bind(this)},
                            box:{
                                type:'box',
                                fields:{
                                    lendRate:'费率(借)',
                                    loanRate:'费率(贷)',
                                    outRate:'费率(外)',
                                    originRate:'费率(本)',
                                    specialCoast:'特殊计费',
                                    level:'商户等级',
                                    expansion:'拓展人',
                                    expansionCoast:'拓展费用',
                                    platform:'所属平台',
                                    bankAgent:'银行经办人',
                                    expansionType:'拓展方式',
                                    channel:'渠道',
                                    transactionControl:'交易控制',
                                    receiveDate:{name:'收单日期', type:'date'},
                                    postcode:'邮政编码',
                                    serialNumber:'申请序号',
                                    risk:'风险等级',
                                    authorized:'被授权人',
                                    authorizedIdcode:{name:'身份证', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'被授权人身份证'},
                                    authorizedValidityStartDate:{name:'有效期开始', type:'date', title:'被授权人身份证有效期开始'},
                                    authorizedValidityEndDate:{name:'有效期结束', type:'date', title:'被授权人身份证有效期结束'},
                                    registerDate:{name:'登记日期', type:'date'},
                                    startDate:{name:'启用日期', type:'date'},
                                    aliCoastRate:{name:'支付宝费率'},
                                    wechatCoastRate:{name:'微信费率'},
                                    // salesman:'业务员',
                                    // salesmanNum:'业务员工号',
                                    // externalChannels:'外部渠道',
                                    // clientManagerPhone:{name:'客户经理电话', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'客户经理电话'},
                                    // district:'区域',
                                    contractor:'承包人',
                                },
                            },
                            status:{name:"状态", data:this.server.status, type:'select'},
                            servedAt:{name:'安装时间', type:'date', format:'datetime'}
                        }
                    }
                }}
            />
        );
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup', 'auditStatusDup', 'location', 'qrcode']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }

                let client = JSON.parse(data[0]);
                this.setState( {
                    client: [client]
                })
                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    let pei = {
                        username:'contact',
                        phone:'tel'
                    };
                    let fields = ['bankAddress', 'address', 'corporate', 'corporateIdcode', 'corporateValidityStartDate', 'corporateValidityEndDate', 'corporatePhone', 'corporateTel', 'corporateFax', 'lendRate', 'loanRate','outRate','originRate','specialCoast', 'level','expansion','expansionCoast','platform','bankAgent','expansionType','channel',
                        'transactionControl', 'receiveDate','postcode','serialNumber','risk','registerDate','startDate','aliCoastRate','wechatCoastRate','authorized', 'authorizedIdcode', 'authorizedValidityStartDate', 'authorizedValidityEndDate'
                    ];
                    for(let k in fields) {
                        let field = fields[k];
                        // if (data.information.hasOwnProperty(field) && !model[field]) {
                            model[field] = data.information[field];
                        // }
                    }
                    for(let k in pei) {
                        let field = pei[k];
                        // if (! model[k] && data.information[field]) {
                            model[k] = data.information[field];
                        // }
                    }
                    if (data.information.receiveBank) {
                        this.server.getService(BankService).getDetail(data.information.receiveBank, bank => {
                            this.setState({
                                receiveBank:[bank]
                            })
                        })
                    }
                    if (data.information.bank) {
                        this.server.getService(BankService).getDetail(data.information.bank, bank => {
                            this.setState({
                                bank:[bank]
                            })
                        })
                    }
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }
    choosePos() {
        this.layOpen({
            title:'选择POS机',
            content:'/admin/device/pos/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    pos:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseKeyboard() {
        this.layOpen({
            title:'选择键盘',
            content:'/admin/device/keyboard/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    keyboard: [JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseSim() {
        this.layOpen({
            title:'SIM卡',
            content:'/admin/device/sim/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    sim:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    receiveBank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.receiveBank.length}
                list={state.receiveBank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'receiveBankId'}
            />
        )
    }

    chooseReceiveBank(form) {
        this.layOpen({
            title:'选择收单银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    receiveBank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择开户银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }
}
