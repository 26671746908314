import View from "../../../../../vendor/framework/view";
import Form from "../../layout/form";
import UserService from "../../../services/user";
import UserTypeListLayout from "../../running/userType/layout";
import UserAttrListLayout from "../../running/userType/attr/layout";
import areasFile from '../../../../../vendor/files/areas.json';
import BankListLayout from "../../running/bank/layout";
import UserTypeService from "../../../services/running/userType";
import UserAttrService from "../../../services/running/userAttr";

export default class UserAdd extends View
{
    boot() {
        this.state = {
            model:{
                name:'',
                status:1,
                isCompany:0,
                address:'',
                longitude:'',
                latitude:'',
                facadeImg:[]
            },
            type:[],
            attr:[],
            company:[],
            receiveBank:[],
            bank:[]
        }
    }

    service() {
        return UserService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getService(UserTypeService).getList(success => {
            let data = [{id:'',name:'--请选择--'}];
            this.setState({
                type:data.concat(success.data)
            })
        })
        this.server.getService(UserAttrService).getList(success => {
            let data = [{id:'',name:'--请选择--'}];
            this.setState({
                attr:data.concat(success.data)
            })
        })
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    base:{
                        type:'group',
                        name:'基础信息',
                        fields:{
                            box:{
                                type:'box',
                                fields:{
                                    code:'商户编号',
                                    name:'商户名称',
                                    phone:'登录手机号',
                                    typeId:{name:'类型', type:'select', data:this.state.type, option:['id', 'name']},
                                    attrId:{name:'属性', type:'select', data:this.state.attr, option:['id', 'name']},
                                    lendRate:'费率(借)',
                                    loanRate:'费率(贷)',
                                    outRate:'费率(外)',
                                    originRate:'费率(本)',
                                    specialCoast:'特殊计费',
                                    level:'商户等级',
                                    expansion:'拓展人',
                                    expansionCoast:'拓展费用',
                                    platform:'所属平台',
                                    bankAgent:'银行经办人',
                                    expansionType:'拓展方式',
                                    channel:'渠道',
                                    transactionControl:'交易控制',
                                    receiveDate:{name:'收单日期', type:'date'},
                                    postcode:'邮政编码',
                                    serialNumber:'申请序号',
                                    risk:'风险等级',
                                    salesman:'业务员',
                                    salesmanNum:'业务员工号',
                                    externalChannels:'外部渠道',
                                    clientManagerPhone:{name:'客户经理电话', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'客户经理电话'},
                                    district:'区域',
                                    status:{name:'状态', type: 'select', data: this.server.auditStatus}
                                }
                            },
                            facadeImg:{name:'门面照', type: 'slide'},
                            qrcode:{name:'二维码', type:'image'},
                            receiveBank:{name:'收单银行', type:'choose', component: this.receiveBank.bind(this), click: this.chooseReceiveBank.bind(this)},
                            bank:{name:'开户银行', type:'choose', component: this.bank.bind(this), click: this.chooseBank.bind(this)},
                            province:{name:'省市区', type:'area', fields:['province', 'city', 'area'], data: areasFile, value:'name'},
                            button:{name:'选择位置', type:'button', click: this.chooseLocation.bind(this)},
                            longitude:'经度',
                            latitude:'纬度',
                            bankAddress:{name:'银行商户地址', type:'textarea', labelStyle:{whiteSpace: 'nowrap'}},
                            address:{name:'商户地址', type:'textarea'},
                            remark:{name:'备注', type:'textarea'},
                        }
                    },
                    contact:{
                        type:'group',
                        name:'联系人信息',
                        fields:{
                            box:{
                                type:'box',
                                fields:{
                                    corporate:'法人代表',
                                    corporateIdcode:'证件号',
                                    corporateValidityStartDate:{name:'有效期开始', type:'date'},
                                    corporateValidityEndDate:{name:'有效期结束', type:'date'},
                                    corporatePhone:'法人手机',
                                    corporateTel:'法人固话',
                                    corporateFax:'法人传真',
                                    contact:'联系人',
                                    tel:'联系电话',
                                }
                            }
                        }
                    },
                    other:{
                        type:'group',
                        name:'其他信息',
                        fields:{
                            box:{
                                type:'box',
                                fields:{
                                    authorized:'被授权人',
                                    authorizedIdcode:{name:'身份证', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, showTitle:true},
                                    authorizedValidityStartDate:{name:'有效期开始', type:'date'},
                                    authorizedValidityEndDate:{name:'有效期结束', type:'date'},
                                    registerDate:{name:'登记日期', type:'date'},
                                    startDate:{name:'启用日期', type:'date'},
                                    aliCoastRate:{name:'支付宝费率'},
                                    wechatCoastRate:{name:'微信费率'}
                                }
                            }
                        }
                    }
                }}
            />
        )
    }

    type() {
        return (
            <UserTypeListLayout
                autoload={false}
                show={this.state.type.length}
                list={this.state.type}
                paging={false}
                searchShow={false}
                btnShow={false}
                visibles={['id', 'name']}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'typeId'}
            />
        )
    }

    chooseType() {
        this.layOpen({
            title:'选择类型',
            content:'/admin/running/userType/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || !data.length) {
                    return layer.msg('请选择类型');
                }
                this.setState({
                    type:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    attr() {
        return (
            <UserAttrListLayout
                autoload={false}
                show={this.state.attr.length}
                list={this.state.attr}
                paging={false}
                searchShow={false}
                btnShow={false}
                visibles={['id', 'name']}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'attrId'}
            />
        )
    }

    chooseAttr() {
        if (! this.state.type.length) {
            return layer.msg('请先选择类型');
        }

        this.layOpen({
            title:'选择属性',
            content:'/admin/running/userAttr/choose/list?typeId='+this.state.type[0].id,
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || !data.length) {
                    return layer.msg('请选择属性');
                }
                this.setState({
                    attr:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseLocation(form) {
        this.layOpen({
            title:'选择位置',
            content:'/admin/map?city='+form.state.model.city+'&lon='+form.state.model.longitude+'&lat='+form.state.model.latitude,
            form: true,
            yes: (fields, index) => {
                form.setModel({
                    address:fields.address,
                    longitude:fields.lng,
                    latitude:fields.lat
                })
                layer.close(index);
            }
        })
    }

    receiveBank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.receiveBank.length}
                list={state.receiveBank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'receiveBankId'}
            />
        )
    }

    chooseReceiveBank(form) {
        this.layOpen({
            title:'选择收单银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    receiveBank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择开户银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

}
