import View from "../../../../vendor/framework/view";
import Auth from "../../services/auth";
import $ from 'jquery';
import style from './index.module.css';
import { Fragment } from 'react';

class Login extends View
{
	boot() {
		this.state = {
			account:'',
			password:'',
			jigsawFlag:false,
			keepState:false,
			accountDup:'',
			accountFlag:false,
			history:[]
		}

		this.requireCss([
			'/plugins/slidingverification/css/jigsaw.css',
			// '/plugins/jQuery-tpcl/css/popup.css',
		])

		this.requireJs([
			'/plugins/slidingverification/js/jigsaw.js',
			// '/plugins/jQuery-tpcl/js/fragment.js',
			'/plugins/ribbon/ribbon.js',
		])
	}

	service() {
		return Auth;
	}

	componentDidMount() {
		let self = this;
		/* global layui,jigsaw */
		layui.use(['form'], () => {
			var form = layui.form;

			// $('body').css({
			// 	background:'url('+img+')',
			// 	backgroundSize:'100%',
			// 	backgroundRepeat:'no-repeat'
			// })

			// let width = document.body.clientWidth;

			// setInterval(() => {
			// 	let bgArr = [1, 2, 3, 4],
			// 		img = '/images/bg'+(bgArr[parseInt(Math.random() * bgArr.length)])+'.jpg';
				// var fragmentConfig = {
				// 	container : '.img-flex',//显示容器
				// 	line : 10,//多少行
				// 	column : 20,//多少列
				// 	width : width,//显示容器的宽度
				// 	animeTime : 5000,//最长动画时间,图片的取值将在 animeTime*0.33 + animeTime*0.66之前取值
				// 	img : img//图片路径
				// };
				// fragmentImg(fragmentConfig);
			// }, 8000)

			form.on('checkbox(keepState)', (data) => {
				self.setState({
					keepState:data.elem.checked
				})
			})

			self.server.getRemember((data) => {
				self.setState({
					account:data.account,
					accountDup:data.accountDup,
					password:data.password,
					keepState:data.keepState,
					accountFlag:true
				})
				form.render('checkbox');
			})


			$(document).ready(() => {
				setTimeout(() => {
					// self.state.verifyCode = new window.GVerify("v_container");
					jigsaw.init(document.getElementById('captcha'), function () {
						self.setState({
							jigsawFlag:true
						}, () => {
							self.login();
						})
					}, () => {
						self.setState({
							jigsawFlag:false
						})
					})
				}, 500)
			})
		})
	}

	render() {
	 	return (
	 		<Fragment>
				<div className={[style.container + ' ' +style.slideLeft]}>
					<div className={"layadmin-user-login layadmin-user-display-show"} id="LAY-user-login">
						<div className="layadmin-user-login-main">
							<div className={style.logo}><img src="/imgs/logo-big.png" alt=""/></div>
							<div className="layadmin-user-login-box layadmin-user-login-header">
								<h2>后台管理系统</h2>
								{/*<p>任务管理后台管理系统</p>*/}
							</div>
							<div className="layadmin-user-login-box layadmin-user-login-body layui-form">
								<div className="layui-form-item" onMouseLeave={e => this.closeHistory()}>
									<label className="layadmin-user-login-icon layui-icon layui-icon-username" htmlFor="LAY-user-login-username"></label>
									<input type="text" name="account" onFocus={e => this.getHistory(e)} onChange={e => this.onInputChange(e)} autoComplete={"off"} value={this.state.account} placeholder="用户名" className="layui-input" />
									{
										this.state.history.length ?
											<div className={style.history}>
												<ul>
													{
														this.state.history.map((val, index) => {
															return (
																<li onClick={e => this.chooseHistory(val)} key={index}>{val.accountDup}</li>
															)
														})
													}
												</ul>
											</div>
											:''
									}
								</div>
								<div className="layui-form-item">
									<label className="layadmin-user-login-icon layui-icon layui-icon-password" htmlFor="LAY-user-login-password"></label>
									<input type="password" name="password" onChange={e => this.onInputChange(e)} value={this.state.password}  placeholder="密码" className="layui-input" />
								</div>
								<div className="layui-form-item">
									<div id="captcha" style={{position: 'relative'}} data-type="password"></div>
								</div>
								<div className="layui-form-item">
									<input type="checkbox" name={"keepState"} onChange={e => this.onInputChange(e)} checked={this.state.keepState}  value={this.state.keepState} title={"记住密码"} lay-skin="primary" lay-filter={"keepState"}/>
								</div>
								<div className="layui-form-item">
									{/*<button className="layui-btn layui-btn-fluid" onClick={e => this.login()}>登 入</button>*/}
								</div>
							</div>
						</div>
						<div className="layui-trans layadmin-user-login-footer">
							<p>© 2020 <a href="http://gibbous.net" target="_blank" rel="noopener noreferrer">http://xxxx.com</a></p>
						</div>
					</div>
				</div>
				{/*<div className="popup-container">*/}
				{/*	<div className="img-flex"></div>*/}
				{/*</div>*/}
			</Fragment>

	 	);
	}

	onInputChange(e) {
	 	let inputName = e.target.name,
	 	    inputValue = e.target.value,
			obj = {};
	 		obj.accountFlag = this.state.accountFlag;

	 	if (inputName === 'mobile' && obj.accountFlag) {
			obj.accountFlag = false;
			inputValue = this.state.account;
		}
	 	obj[inputName] = inputValue;
	 	obj.history = this.server.getLikeHistory(inputValue);
	 	this.setState(obj)
	}

	login() {
		/* global layer */
		 var data = {
			 	 account: this.state.account,
				 password:this.state.password,
				 jigsawFlag:this.state.jigsawFlag,
				 keepState:this.state.keepState
			 };


		 this.server.signIn(data, (user) => {
			 layer.msg((user.realname || user.username)+',欢迎您回来!');
			 if (window.top !== window.self) {
				 if (window.top.document.layAdminInit) {
					 window.top.document.layAdminInit();
				 } else {
					 setTimeout(() => {
						 window.top.location.reload();
					 }, 2000)
				 }
			 }
			 this.app.redirect(this.server.getLastUrl('/admin/index'), 2000)
		 }, (msg) => {
			 jigsaw.reset();
			 layer.msg(msg);
		 })
	 }

	getHistory(e) {
		let val = e.target.value,
		data = this.server.getLikeHistory(val);
		this.setState({
			history:data
		})
	}

	chooseHistory(item) {
		this.setState({
			accountDup:item.accountDup,
			account:item.account,
			password:item.password,
			history:[],
			keepState:item.keepState,
			accountFlag:true
		})
	}

	closeHistory() {
		this.setState({
			history:[]
		})
	}
}
export default Login;
